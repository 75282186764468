import React, { useEffect, useRef, useState } from "react";
import PlayerControlsGroup from "@/components/player/player-controls-group";
import { RecordingEvent } from "@/types/pptypes";
import { enterFullscreen, exitFullscreen } from "@/helpers/fullscreen";
import { onWidthChange } from "@/helpers/on-size-changed";
import { waitForIt } from "@/helpers/wait-for-defined";

import rrwebPlayer from "rrweb-player";
import "rrweb-player/dist/style.css";

const syncPlayerDimensions = (player: rrwebPlayer | null, rect: DOMRectReadOnly) => {
  // @ts-expect-error method exists but is not in the type definition
  player?.$set({ width: rect.width, height: (rect.width / 16) * 9 });
  player?.triggerResize();
};

interface PPPlayerProps {
  events: RecordingEvent[];
  highlight?: {
    start_time_in_s: number;
    end_time_in_s: number;
  };
  shareUrl?: string;
  onExternalLinkClicked?: () => void;
  showCustomControls?: boolean;
  showNativeControls?: boolean;
}

const PPPlayer: React.FC<PPPlayerProps> = ({
  events,
  highlight,
  shareUrl,
  onExternalLinkClicked,
  showCustomControls = true,
  showNativeControls = false,
}) => {
  const playerRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const controlsRef = useRef<HTMLDivElement>(null);

  const playerInstanceRef = useRef<rrwebPlayer | null>(null);

  const [playerState, setPlayerState] = useState("");
  const [playerProgress, setPlayerProgress] = useState(0);

  useEffect(() => {
    waitForIt(() => (wrapperRef.current && playerRef.current ? true : undefined)).then(() => {
      if (!playerRef.current || !wrapperRef.current) {
        return;
      }

      if (playerInstanceRef.current) {
        playerInstanceRef.current.pause();
        playerRef.current.innerHTML = "";
      }

      const rect = wrapperRef.current.getBoundingClientRect();

      const newPlayer = new rrwebPlayer({
        target: playerRef.current,
        props: {
          events: events,
          autoPlay: true,
          UNSAFE_replayCanvas: false,
          width: rect.width,
          height: (rect.width / 16) * 9,
          showController: showNativeControls,
          speed: 1,
          skipInactive: true,
        },
      });

      playerInstanceRef.current = newPlayer;

      if (!highlight) {
        return;
      }

      playerInstanceRef.current.goto(highlight.start_time_in_s * 1000);
      playerInstanceRef.current.addEventListener("ui-update-current-time", (event) => {
        const currentTime = event.payload as number;
        if (currentTime > highlight.end_time_in_s * 1000) {
          playerInstanceRef.current?.goto(highlight.start_time_in_s * 1000);
          setPlayerProgress(100);
          return;
        }

        const currentProgressInMs = currentTime - highlight.start_time_in_s * 1000;
        const totalTimeInMs = highlight.end_time_in_s * 1000 - highlight.start_time_in_s * 1000;
        const progress = (currentProgressInMs / totalTimeInMs) * 100;
        setPlayerProgress(Math.ceil(progress));
      });

      playerInstanceRef.current.addEventListener("ui-update-player-state", (event) => {
        setPlayerState(event.payload === "playing" ? "playing" : "paused");
      });
    });

    return () => {
      if (playerInstanceRef.current) {
        playerInstanceRef.current.pause();
      }
    };
  }, [events, highlight, showNativeControls]);

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (document.fullscreenElement) {
        const controlsHeight = 80; //TODO hack
        const style = document.createElement("style");
        style.innerHTML = `
        [data-id="pp-player-wrapper"] {
          width: 100%!important;
          height: 100%!important;
        }
        
        .rr-player {
          width: 100vw!important;
          height: calc(100vh - ${controlsHeight}px)!important;
        }
        `;
        style.id = "pp-rrweb-player-style";
        style.type = "text/css";
        document.head.appendChild(style);
        playerInstanceRef.current?.triggerResize();
      } else {
        document.querySelector("#pp-rrweb-player-style")?.remove();
        playerInstanceRef.current?.triggerResize();
      }
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => document.removeEventListener("fullscreenchange", handleFullscreenChange);
  }, []);

  const onPlayClicked = () => playerInstanceRef.current?.play();
  const onPauseClicked = () => playerInstanceRef.current?.pause();
  const onScrubRequest = (value: number) => {
    if (playerInstanceRef.current && highlight) {
      const totalTimeInMs = highlight.end_time_in_s * 1000 - highlight.start_time_in_s * 1000;
      const newTime = highlight.start_time_in_s * 1000 + (value * totalTimeInMs) / 100;
      playerInstanceRef.current.goto(newTime);
    }
  };
  const onSpeedChanged = (speedMultiplier: number) =>
    playerInstanceRef.current?.setSpeed(speedMultiplier);
  const onMaximizeClicked = () => {
    if (playerInstanceRef.current) {
      const playerElement = wrapperRef.current as HTMLElement;
      if (document.fullscreenElement) {
        exitFullscreen();
      } else {
        enterFullscreen(playerElement);
      }
    }
  };
  const onShareClicked = () => {
    exitFullscreen();
  };

  useEffect(() => {
    onWidthChange(wrapperRef, (rect) => {
      const player = playerInstanceRef.current;

      syncPlayerDimensions(player, rect);
    });
  }, []);

  return (
    <div ref={wrapperRef} data-id={"pp-player-wrapper"}>
      <div ref={playerRef}></div>
      {showCustomControls && (
        <PlayerControlsGroup
          playerProgress={playerProgress}
          onScrubRequest={onScrubRequest}
          speed={1}
          onSpeedChanged={onSpeedChanged}
          playerState={playerState}
          onPlayClicked={onPlayClicked}
          onPauseClicked={onPauseClicked}
          onExternalLinkClicked={onExternalLinkClicked}
          shareUrl={shareUrl}
          onMaximizeClicked={onMaximizeClicked}
          ref={controlsRef}
          onShareClicked={onShareClicked}
        />
      )}
    </div>
  );
};

export default PPPlayer;
