export type SiteConfig = typeof siteConfig;

export const siteConfig = {
  name: "Vite + NextUI",
  description: "Make beautiful websites regardless of your design experience.",
  navItems: [
    {
      label: "Home",
      href: "/",
      access: "regular",
    },
    {
      label: "Admin",
      href: "/admin",
      access: "internal",
    },
    {
      label: "Contact",
      href: "mailto:founders@getpathpilot.com",
      target: "_blank",
      access: "regular",
    },
  ],
  navMenuItems: [
    {
      label: "Home",
      href: "/",
      access: "regular",
    },
    {
      label: "Admin",
      href: "/admin",
      access: "internal",
    },
    {
      label: "Contact",
      href: "mailto:founders@getpathpilot.com",
      target: "_blank",
      access: "regular",
    },
  ],
  links: {
    github: "https://github.com/nextui-org/nextui",
    twitter: "https://twitter.com/getnextui",
    docs: "https://nextui-docs-v2.vercel.app",
    discord: "https://discord.gg/9b6yyZKmH4",
    sponsor: "https://patreon.com/jrgarciadev",
  },
};
