import { forwardRef } from "react";
import PlayerScrubber from "@/components/player/player-scrubber";
import PlayerControls from "@/components/player/player-controls";

interface PlayerControlsGroupProps {
  playerProgress: number;
  onScrubRequest: (value: number) => void;
  speed: number;
  onSpeedChanged: (speedMultiplier: number) => void;
  playerState: string;
  onPlayClicked: () => void;
  onPauseClicked: () => void;
  onExternalLinkClicked?: () => void;
  shareUrl?: string;
  onMaximizeClicked: () => void;
  onShareClicked: () => void;
}

const PlayerControlsGroup = forwardRef<HTMLDivElement, PlayerControlsGroupProps>((props, ref) => {
  return (
    <div ref={ref} className="rounded-md bg-[hsl(var(--nextui-background))]">
      <PlayerScrubber playerProgress={props.playerProgress} onScrubRequest={props.onScrubRequest} />
      <PlayerControls
        speed={props.speed}
        onSpeedChanged={props.onSpeedChanged}
        playerState={props.playerState}
        onPlayClicked={props.onPlayClicked}
        onPauseClicked={props.onPauseClicked}
        onExternalLinkClicked={props.onExternalLinkClicked}
        shareUrl={props.shareUrl}
        onMaximizeClicked={props.onMaximizeClicked}
        onShareClicked={props.onShareClicked}
      />
    </div>
  );
});

export default PlayerControlsGroup;
