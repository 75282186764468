import { Button } from "@nextui-org/react";
import React, { useState } from "react";

interface TruncatedTextProps {
  text: string;
  maxLength: number;
  shouldExpand: boolean;
}

const TruncatedText: React.FC<TruncatedTextProps> = ({ text, maxLength, shouldExpand }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (text.length <= maxLength) {
    return <p className="py-2 text-justify text-small text-foreground">{text}</p>;
  }

  const truncatedText = isExpanded ? text : text.slice(0, maxLength);

  return (
    <div className="py-2 text-justify text-small text-foreground">
      <p>
        {truncatedText}
        {!isExpanded && "..."}
        {!shouldExpand && <span className="text-primary underline">{"   MORE DETAILS"}</span>}
        {shouldExpand && (
          <Button
            onPress={() => setIsExpanded(!isExpanded)}
            variant="light"
            size="sm"
            className="text-primary hover:underline focus:outline-none"
          >
            {isExpanded ? "Read less" : "Read more"}
          </Button>
        )}
      </p>
    </div>
  );
};

export default TruncatedText;
