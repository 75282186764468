import { SplitPane, Pane } from "@/helpers/split-pane";
import { ComponentProps, FC, useCallback, useState } from "react";
import clsx from "clsx";
import { appMediator } from "@/helpers/app-mediator";

export type PaneProps = ComponentProps<typeof Pane>;

export type SplitGroupPaneProps = {
  defaultSizes: (number | string)[];
  leftPaneProps?: PaneProps;
  rightPaneProps?: PaneProps;
  groupClassName?: string;
  leftPanelChildren?: React.ReactNode;
  rightPanelChildren?: React.ReactNode;
};

export const SplitGroupPane: FC<SplitGroupPaneProps> = (props) => {
  const {
    leftPanelChildren,
    rightPanelChildren,
    defaultSizes,
    groupClassName,
    leftPaneProps,
    rightPaneProps,
  } = props;
  const [sizes, setSizes] = useState(defaultSizes);

  const onPanelsSizeChanged = useCallback(() => {
    appMediator.fire("onPanelsSizeChanged");
  }, []);

  return (
    <div className={clsx("relative h-[calc(100vh-64px)]", groupClassName)}>
      <SplitPane split="vertical" sizes={sizes} onChange={setSizes} onDragEnd={onPanelsSizeChanged}>
        <Pane {...leftPaneProps}>{leftPanelChildren}</Pane>
        <Pane {...rightPaneProps}>{rightPanelChildren}</Pane>
      </SplitPane>
      <div className="absolute inset-x-0 top-0 h-[2px] bg-panel-border" />
    </div>
  );
};
