export function exitFullscreen() {
  return (
    document.exitFullscreen?.() ||
    // @ts-expect-error - Firefox
    document.mozCancelFullScreen?.() ||
    // @ts-expect-error - Chrome
    document.webkitExitFullscreen?.() ||
    // @ts-expect-error - IE
    document.msExitFullscreen?.()
  );
}

export function enterFullscreen(element: HTMLElement) {
  return (
    element.requestFullscreen?.() ||
    // @ts-expect-error - Firefox
    element.mozRequestFullScreen?.() ||
    // @ts-expect-error - Chrome
    element.webkitRequestFullscreen?.() ||
    // @ts-expect-error - IE
    element.msRequestFullscreen?.()
  );
}
