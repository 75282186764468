import { Recording } from "@/types/pptypes";
import { v4 as uuidv4 } from "uuid";
import * as tus from "tus-js-client";
import { fetchWithSession } from "./fetch/fetch-with-session";
import { buildUrl, Endpoint } from "./fetch/endpoint";
import { supabaseClient } from "@/helpers/supabase-client";

export type TRecording = Recording & { highlights_count: number };

async function uploadFile(
  bucketName: string,
  fileName: string,
  file: File,
  progressListener?: (message: string) => void,
) {
  const projectUrl = import.meta.env.VITE_SUPABASE_URL;
  const {
    data: { session },
  } = await supabaseClient.auth.getSession();

  if (!session) {
    throw new Error("No active session found");
  }

  return new Promise((resolve, reject) => {
    const upload = new tus.Upload(file, {
      endpoint: `${projectUrl}/storage/v1/upload/resumable`,
      retryDelays: [0, 3000, 5000, 10000, 20000],
      headers: {
        authorization: `Bearer ${session.access_token}`,
        "x-upsert": "true", // optionally set upsert to true to overwrite existing files
      },
      uploadDataDuringCreation: true,
      removeFingerprintOnSuccess: true, // Important if you want to allow re-uploading the same file https://github.com/tus/tus-js-client/blob/main/docs/api.md#removefingerprintonsuccess
      metadata: {
        bucketName: bucketName,
        objectName: fileName,
        contentType: "application/json",
        cacheControl: "3600",
      },
      chunkSize: 6 * 1024 * 1024, // NOTE: it must be set to 6MB (for now) do not change it
      onError: function (error: Error) {
        console.error("Failed because: " + error);
        reject(error);
      },
      onProgress: function (bytesUploaded: number, bytesTotal: number) {
        const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
        if (progressListener) {
          progressListener(`Uploaded ${percentage}%`);
        }
      },
      onSuccess: function () {
        if (progressListener) {
          progressListener("Upload finished");
        }
        resolve(upload.url);
      },
    });

    // Check if there are any previous uploads to continue.
    return upload.findPreviousUploads().then(function (previousUploads) {
      // Found previous uploads so we select the first one.
      if (previousUploads.length) {
        upload.resumeFromPreviousUpload(previousUploads[0]);
      }

      // Start the upload
      upload.start();
    });
  });
}

export const uploadRecordings = async (
  files: File[],
  organizationId: string,
  progressListener?: (message: string) => void,
) => {
  const uuid = uuidv4();
  const fileName = `${uuid}.json`;
  await uploadFile("recordings-public", fileName, files[0], progressListener);

  if (progressListener) {
    progressListener("Processing File");
  }

  try {
    const data = (await fetchWithSession(buildUrl(Endpoint.recordings), {
      method: "POST",
      body: {
        organization_id: organizationId,
        ext_json_file: fileName,
      },
    })) as Partial<Recording>;
    const fileContent = await files[0].text();
    return { ...data, json: fileContent } as Recording;
  } catch (error) {
    console.error(error);
    throw Error("Error uploading recording");
  }
};

export const updateRecordingMetadata = async (
  recordingId: number,
  externalUrl: string,
  userInfo: string,
  timestamp: number,
) => {
  try {
    const data = (await fetchWithSession(buildUrl(Endpoint.recordings_by_id, { recordingId }), {
      method: "PATCH",
      body: {
        ext_resource_url: externalUrl,
        ext_user_info: userInfo,
        ext_timestamp: timestamp,
      },
    })) as Partial<Recording>;
    return data;
  } catch (error) {
    console.error(error);
    throw Error("Error updating recording metadata");
  }
};

export const fetchFileFromSupabase = async (recording: Recording): Promise<string> => {
  try {
    const fileName = recording.ext_json_file;
    const bucket = recording.creation_mode == "manual" ? "recordings-public" : "recordings-batch";

    const { data, error } = await supabaseClient.storage.from(bucket).download(fileName);

    if (error) {
      console.error(error);
      throw new Error(error.message);
    }

    return data.text();
  } catch (err) {
    console.error("Download error:", err);
    return "";
  }
};

export const fetchRecordingsByOrganization = async (
  organizationId: string,
  page: number = 1,
  pageSize: number = 10,
) => {
  try {
    const data: { recordings: TRecording[]; count: number } = await fetchWithSession(
      buildUrl(
        Endpoint.recordings_by_organization,
        { organizationId: organizationId },
        { page: page, size: pageSize },
      ),
    );

    const recordingsWithJson = await Promise.all(
      data.recordings.map(async (recording) => {
        const jsonContent = await fetchFileFromSupabase(recording);
        return {
          ...recording,
          json: jsonContent,
          highlights_count: +(recording.highlights_count ?? 0),
        } as TRecording;
      }),
    );

    return {
      recordings: recordingsWithJson,
      totalCount: data.count,
      currentPage: page,
      pageSize: pageSize,
      totalPages: Math.ceil((data.count ?? 0) / pageSize),
    };
  } catch (error) {
    console.error(error);
    throw Error("Error fetching recordings by organization");
  }
};

export const fetchRecordingById = async (recordingId: number) => {
  const { data, error } = await supabaseClient.from("recordings").select("*").eq("id", recordingId);

  if (error) {
    throw new Error(`Error fetching recording: ${error.message}`);
  }
  const recording = data[0] as Recording;

  const jsonContent = await fetchFileFromSupabase(recording);

  return { ...recording, json: jsonContent };
};
