import { sleep } from "./sleep.ts";

export type Result<T> = [T, null] | [null, Error];

export type WaitUntilReturnIsDefinedOpts = {
  timeout?: number;
  tryInterval?: number;
};

export const waitForIt = async <T>(
  fn: () => T | undefined,
  opts?: WaitUntilReturnIsDefinedOpts,
): Promise<Result<T>> => {
  const { timeout = 5000, tryInterval = 100 } = opts || {};
  const start = Date.now();
  let ret: T | undefined;
  while (ret == null && Date.now() - start < timeout) {
    ret = fn();
    await sleep(tryInterval);
  }

  if (ret == null) {
    return [null, new Error("Timeout waiting for return value")];
  }

  return [ret, null];
};
