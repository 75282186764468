import { Navbar } from "@/components/navbar";
import { FC } from "react";

export default function DefaultLayout({ children }: { children: React.ReactNode }) {
  return (
    <div className="relative flex h-screen flex-col">
      <Navbar />
      <main className="container mx-auto max-w-7xl grow px-6 pt-4">
        <div className="flex h-auto w-full flex-row">
          {/*<SidebarLoggedIn/>*/}
          {children}
        </div>
      </main>
      <footer className="flex w-full items-center justify-center py-3">
        <p>PathPilot 2024</p>
      </footer>
    </div>
  );
}

export const AppLayout: FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div className="relative">
      <Navbar />
      {children}
    </div>
  );
};
