import { RecordingMetadata } from "@/pages/admin";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import { useEffect, useState } from "react";

interface RecordingModalProps {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  onSubmit: (
    recordingId: number,
    recordingUrl: string,
    userInfo: string,
    timestamp: number,
  ) => void;
  metadata: RecordingMetadata;
}

const RecordingModal: React.FC<RecordingModalProps> = ({
  isOpen,
  onOpenChange,
  onSubmit,
  metadata,
}) => {
  const [recordingUrl, setRecordingUrl] = useState("");
  const [recordingTimestamp, setRecordingTimestamp] = useState("");

  useEffect(() => {
    const date = new Date(metadata.timestamp);
    setRecordingTimestamp(date.toLocaleString());
  }, []);

  const handleSubmit = async () => {
    await onSubmit(metadata.recordingId, recordingUrl, metadata.userInfo, metadata.timestamp);
    setRecordingUrl("");
    onOpenChange(false);
  };

  return (
    <Modal isOpen={isOpen} onOpenChange={onOpenChange} placement="top-center">
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1">{`Confirm Recording Metadata`}</ModalHeader>
            <ModalBody>
              <Input
                id="recordingUrl"
                autoFocus
                label="Recording Url"
                placeholder="Enter the posthog url"
                variant="bordered"
                value={recordingUrl}
                type="url"
                onChange={(e) => setRecordingUrl(e.target.value)}
              />
              <Input
                id="userInfo"
                label="User Info (extracted from Json)"
                variant="bordered"
                value={metadata.userInfo}
                disabled
              />
              <Input
                id="timestamp"
                label="Recording Timestamp"
                variant="bordered"
                value={recordingTimestamp}
                disabled
              />
            </ModalBody>
            <ModalFooter>
              <Button color="danger" variant="flat" onPress={onClose}>
                Cancel
              </Button>
              <Button color="primary" onPress={handleSubmit}>
                Submit
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default RecordingModal;
