import { fetchFileFromSupabase, fetchRecordingsByOrganization } from "@/api/services/recordings";
import { insertHighlight } from "@/api/services/highlights";
import DefaultLayout from "@/layouts/default";
import { Organization, Recording, RecordingEvent } from "@/types/pptypes";
import {
  Button,
  Selection,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  useDisclosure,
} from "@nextui-org/react";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";
import HighlightModal from "@/components/highlights/highlights-modal";
import { useAdminAuth } from "@/auth/use-admin-auth";
import { TRecording } from "@/api/services/recordings";
import PPPlayer from "@/components/player/pp-player";

const pageSize = 5;
export default function HighlightsAdmin() {
  const { isAdmin, loading: authLoading } = useAdminAuth();
  const location = useLocation();
  const [organization, setOrganization] = useState<Organization | null>(null);
  const [recordingList, setRecordingList] = useState<TRecording[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedRecording, setSelectedRecording] = useState<TRecording | null>(null);
  const [events, setEvents] = useState<RecordingEvent[]>([]);
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [clickedRecording, setClickedRecording] = useState<Recording | null>(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    if (authLoading || !isAdmin) {
      return;
    }
    if (location.state && location.state.organization) {
      setOrganization(location.state.organization);
    }
  }, [location.state, authLoading, isAdmin]);

  const fetchNextPageRecordingByOrgId = useCallback(async () => {
    if (!organization) return;
    setLoading(true);
    const data = await fetchRecordingsByOrganization(organization.id, page, pageSize);
    setPage(page + 1);
    setRecordingList([...recordingList, ...(data.recordings as TRecording[])]);
    setHasMore(data.currentPage < data.totalPages);
    setLoading(false);
  }, [organization, page, recordingList]);

  useEffect(() => {
    fetchNextPageRecordingByOrgId();
  }, [organization, fetchNextPageRecordingByOrgId]);

  const handleSelectionChange = async (keys: Selection) => {
    const selectedKey = Array.from(keys)[0];
    const selectedRecording = recordingList.at(selectedKey as number)!;
    setSelectedRecording(selectedRecording);

    if (!selectedRecording.json) {
      const jsonContent = await fetchFileFromSupabase(selectedRecording);
      const parsedEvents = JSON.parse(jsonContent);
      setEvents(parsedEvents.data.snapshots);
    } else {
      const parsedEvents = JSON.parse(selectedRecording.json);
      setEvents(parsedEvents.data.snapshots);
    }
  };

  const handleNewHighlight = async (
    startTime: number,
    endTime: number,
    title: string,
    summary: string,
    recording: TRecording,
  ) => {
    if (!recording) {
      console.error("No recording defined for new Highlight");
      return;
    }
    await insertHighlight({
      recording_id: recording.id,
      start_time_in_s: startTime,
      end_time_in_s: endTime,
      title,
      summary,
    });

    //optimistic update on highlight count
    const updatedRecording = { ...recording, highlights_count: recording.highlights_count + 1 };
    const index = recordingList.findIndex((r) => r.id === recording.id);
    const newRecordingList = [...recordingList];
    newRecordingList[index] = updatedRecording;
    setRecordingList(newRecordingList);
  };

  function loadMore(): void {
    fetchNextPageRecordingByOrgId();
  }

  return (
    <DefaultLayout>
      <main className="flex flex-row">
        {organization && (
          <div className="flex flex-row gap-10">
            <div className="w-1/5 min-w-[500px]">
              <h1 className="text-large font-medium">{`Recordings - ${organization.name}`}</h1>
              <Table
                isHeaderSticky
                color="default"
                selectionMode="single"
                aria-label="Recording List"
                onSelectionChange={handleSelectionChange}
                bottomContent={
                  hasMore ? (
                    <div className="flex w-full justify-center">
                      <Button isDisabled={loading} variant="flat" onPress={loadMore}>
                        {loading && <Spinner color="white" size="sm" />}
                        Load More
                      </Button>
                    </div>
                  ) : null
                }
              >
                <TableHeader>
                  <TableColumn key="id">ID</TableColumn>
                  <TableColumn key="createdat">Createad At</TableColumn>
                  <TableColumn key="count">Highlight Count</TableColumn>
                  <TableColumn key="actions">Actions</TableColumn>
                </TableHeader>
                <TableBody isLoading={loading}>
                  {recordingList.map((recording: TRecording, index) => (
                    <TableRow key={index}>
                      <TableCell>{recording.id}</TableCell>
                      <TableCell>
                        {formatDistanceToNow(new Date(recording.created_at), { addSuffix: true })}
                      </TableCell>
                      <TableCell>{JSON.stringify(recording.highlights_count)}</TableCell>
                      <TableCell>
                        <Button
                          color="primary"
                          onPress={() => {
                            setClickedRecording(recording);
                            onOpen();
                          }}
                        >
                          Add Highlight
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
            {selectedRecording && (
              <div>{events && events.length > 0 && <PPPlayer events={events} />}</div>
            )}
          </div>
        )}
        {clickedRecording && (
          <HighlightModal
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            onSubmit={(startTime, endTime, title, summary, recording) =>
              handleNewHighlight(startTime, endTime, title, summary, recording as TRecording)
            }
            recording={clickedRecording}
          />
        )}
      </main>
    </DefaultLayout>
  );
}
