import {
  defaultBreakpoints,
  getHBreakpoints,
  initScreenBreakpoints,
  useScreenBreakpoints,
} from "use-breakpoint-matches";
import { exportAPItoGlobal } from "./expose-api";

const hBreakpoints = defaultBreakpoints;

initScreenBreakpoints({
  hBreakpoints,
});

export const useViewportBreakpoints = () => {
  return useScreenBreakpoints<typeof hBreakpoints, typeof hBreakpoints>();
};

exportAPItoGlobal({ getHBreakpoints });
