const BASE_URL = import.meta.env.VITE_PATH_PILOT_API_BASE_URL;

const version = "v1";
export enum Endpoint {
  highlights = `/${version}/highlights`,
  feedback_highlight = `/${version}/feedback/highlight/:highlightId`,
  highlights_by_id = `/${version}/highlights/id/:highlightId`,
  highlights_by_recording = `/${version}/highlights/recording/:id`,
  highlights_by_organization = `/${version}/highlights/organization/:organizationId`,
  organizations = `/${version}/organizations`,
  organizations_by_domain = `/${version}/organizations/domain/:domain`,
  subscriptions = `/${version}/subscriptions`,
  profile = `/${version}/profile/`,
  profile_by_user_id = `/${version}/profile/user/:userId`,
  recordings = `/${version}/recordings`,
  recordings_by_id = `/${version}/recordings/id/:recordingId`,
  recordings_by_organization = `/${version}/recordings/organization/:organizationId`,
  ingest_recording = `/${version}/ingest/classify/recording/:recordingId`,
}

export interface QueryParams {
  [key: string]: string | number | boolean | undefined;
}

export interface PathParams {
  [key: string]: string | number;
}

export function buildUrl(
  endpoint: Endpoint,
  pathParams?: PathParams,
  queryParams?: QueryParams,
): string {
  let path = `${BASE_URL}${endpoint}`;

  if (pathParams) {
    Object.entries(pathParams).forEach(([key, value]) => {
      path = path.replace(`:${key}`, encodeURIComponent(value.toString()));
    });
  }

  const url = new URL(path);

  if (queryParams) {
    Object.entries(queryParams).forEach(([key, value]) => {
      if (value !== undefined) {
        url.searchParams.append(key, value.toString());
      }
    });
  }

  return url.toString();
}
