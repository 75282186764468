import { insertHighlight } from "@/api/services/highlights";
import { getRecordingClassification } from "@/api/services/ingest";
import { fetchAllOrganizations } from "@/api/services/organizations";
import {
  fetchFileFromSupabase,
  fetchRecordingsByOrganization,
  TRecording,
  updateRecordingMetadata,
  uploadRecordings,
} from "@/api/services/recordings";
import { useAdminAuth } from "@/auth/use-admin-auth";
import HighlightModal from "@/components/highlights/highlights-modal";
import PPPlayer from "@/components/player/pp-player";
import RecordingsList from "@/components/recording/recordings-list";
import RecordingModal from "@/components/recording/recordings-modal";
import { SplitGroupPane } from "@/components/split-group-pane";

import { AppLayout } from "@/layouts/default";
import { LLMClassification, Organization, Recording, RecordingEvent } from "@/types/pptypes";
import { Icon } from "@iconify/react/dist/iconify.js";
import {
  Autocomplete,
  AutocompleteItem,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardProps,
  Chip,
  Divider,
  Input,
  Skeleton,
  Spinner,
  useDisclosure,
} from "@nextui-org/react";
import { Key, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export type RecordingMetadata = {
  recordingId: number;
  userInfo: string;
  timestamp: number;
};

export default function AdminIndex(props: CardProps) {
  const { isAdmin, loading: authLoading } = useAdminAuth();
  const [organizationList, setOrganizationList] = useState<Organization[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingAISummary, setLoadingAISummary] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadMessage, setUploadMessage] = useState("");
  const [uploadedMetadata, setUploadedMetadata] = useState<RecordingMetadata | null>(null);
  const [selectedOrganization, setSelectedOrganization] = useState<Organization | null>(null);
  const [recordings, setRecordings] = useState<TRecording[]>([]);
  const [events, setEvents] = useState<RecordingEvent[]>([]);
  const {
    isOpen: isModalRecordingsOpen,
    onOpen: onOpenModalRecordings,
    onOpenChange: onModalRecordingsOpenChange,
  } = useDisclosure();
  const {
    isOpen: isModalHighlightsOpen,
    onOpen: onOpenModalHighlights,
    onOpenChange: onModalHighlightsOpenChange,
  } = useDisclosure();
  const [selectedRecording, setSelectedRecording] = useState<Recording | null>(null);
  const [aiSummary, setAISummary] = useState<LLMClassification | null>(null);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [hasMoreRecordings, setHasMoreRecordings] = useState(false);
  const PAGE_SIZE = 5;

  useEffect(() => {
    if (authLoading || !isAdmin) {
      return;
    }
    setLoading(true);
    fetchAllOrganizations()
      .then((data) => {
        setOrganizationList(data);
      })
      .catch((error: Error) => {
        console.error("Error fetching organization list:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [authLoading, isAdmin]);

  useEffect(() => {
    if (!selectedOrganization) {
      return;
    }
    triggerRecordingHeadLoad(selectedOrganization.id);
  }, [selectedOrganization]);

  function triggerRecordingHeadLoad(organizationId: string) {
    setPage(1);
    setLoading(true);
    fetchRecordingsByOrganization(organizationId, 1, PAGE_SIZE)
      .then((data) => {
        setRecordings(data.recordings);
        setHasMoreRecordings(data.currentPage < data.totalPages);
      })
      .catch((error: Error) => {
        console.error("Error fetching head load recordings:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length === 0) {
      console.error("No JSON files selected");
      return;
    }
    if (!selectedOrganization) {
      console.error("Error with organization.");
      return;
    }
    setUploading(true);
    const files: File[] = Array.from(event.target.files).filter(
      (file) => file.type === "application/json",
    );
    uploadRecordings(files, selectedOrganization.id, (message: string) => {
      setUploadMessage(message);
    }).then((data: Recording) => {
      if (!data.json) {
        console.error("Recording JSON is undefined");
        setUploading(false);
        return;
      }
      const parsedRecordingJson = JSON.parse(data.json);
      const metadata: RecordingMetadata = {
        recordingId: data.id,
        userInfo: parsedRecordingJson.data.person.name,
        timestamp: parsedRecordingJson.data.snapshots[0].timestamp as number,
      };
      setUploadedMetadata(metadata);
      setUploading(false);
      onOpenModalRecordings();
    });
  };

  function handleNewRecording(
    recordingId: number,
    recordingUrl: string,
    userInfo: string,
    timestamp: number,
  ): void {
    setUploading(true);
    updateRecordingMetadata(recordingId, recordingUrl, userInfo, timestamp).then(() => {
      triggerRecordingHeadLoad(selectedOrganization!.id);
      setUploading(false);
    });
  }

  function onOrganizationSelectionChange(id: Key | null) {
    if (!id) {
      return;
    }
    const selectedOrganization = organizationList.find((org) => org.id === id);
    if (selectedOrganization) {
      setSelectedOrganization(selectedOrganization);
    }
  }

  async function onRecordingClick(recording: Recording) {
    setSelectedRecording(recording);

    if (!recording.json) {
      const jsonContent = await fetchFileFromSupabase(recording);
      const parsedEvents = JSON.parse(jsonContent);
      setEvents(parsedEvents.data.snapshots);
    } else {
      const parsedEvents = JSON.parse(recording.json);
      setEvents(parsedEvents.data.snapshots);
    }

    console.log("Fetching AI summary");
    // Load AI summary
    setLoadingAISummary(true);
    getRecordingClassification(recording).then((data) => {
      console.log("AI summary fetched ", data);
      setAISummary(data);
      setLoadingAISummary(false);
    });
  }

  function addRecording() {
    document.getElementById("fileInput")?.click();
  }

  const handleNewHighlight = async (
    startTime: number,
    endTime: number,
    title: string,
    summary: string,
    recording: TRecording,
  ) => {
    if (!recording) {
      console.error("No recording defined for new Highlight");
      return;
    }
    await insertHighlight({
      recording_id: recording.id,
      start_time_in_s: startTime,
      end_time_in_s: endTime,
      title,
      summary,
    });

    //optimistic update on highlight count
    const updatedRecording = { ...recording, highlights_count: recording.highlights_count + 1 };
    const index = recordings.findIndex((r) => r.id === recording.id);
    const newRecordingList = [...recordings];
    newRecordingList[index] = updatedRecording;
    setRecordings(newRecordingList);
  };

  async function fetchMoreRecordings() {
    if (!selectedOrganization) {
      return;
    }
    const data = await fetchRecordingsByOrganization(selectedOrganization.id, page + 1, PAGE_SIZE);
    setRecordings([...recordings, ...data.recordings]);
    setPage(page + 1);
    setHasMoreRecordings(data.currentPage < data.totalPages);
  }

  const leftPanelChildren = (
    <div className="relative flex h-full min-w-[320px] flex-col">
      <div className="px-6 py-4">
        <h1 className="text-xl font-bold text-foreground">Internal Dashboard</h1>
      </div>
      <div className="flex w-full flex-wrap gap-4 px-6 py-4 md:flex-nowrap">
        <Autocomplete
          label="Choose an Organization"
          className="max-w-xs"
          onSelectionChange={onOrganizationSelectionChange}
        >
          {organizationList.map((organization) => (
            <AutocompleteItem key={organization.id} value={organization.id}>
              {organization.name}
            </AutocompleteItem>
          ))}
        </Autocomplete>
      </div>
      {loading && (
        <div className="flex flex-1 items-center justify-center">
          <Spinner title="Loading Recordings" />
        </div>
      )}
      {!loading && selectedOrganization && (
        <div className="flex flex-1 flex-col p-4">
          {uploading && <Spinner title={uploadMessage} />}
          <RecordingsList
            recordings={recordings}
            fetchMore={() => {
              fetchMoreRecordings();
            }}
            onRecordingClick={onRecordingClick}
            onRightIconPress={addRecording}
            hasMore={hasMoreRecordings}
          />
          <Input
            id="fileInput"
            type="file"
            accept=".json"
            className="hidden"
            onChange={handleFileChange}
          />
        </div>
      )}
      <div className="absolute inset-y-0 right-0 w-[2px] bg-panel-border" />
    </div>
  );

  const rightPanelChildren = (
    <div className="relative size-full overflow-auto">
      <div className="flex flex-row justify-end px-6 py-4">
        <Button
          className="bg-foreground text-background"
          onPress={() => navigate("/register-org")}
          startContent={
            <Icon className="flex-none text-background/60" icon="lucide:plus" width={16} />
          }
        >
          New Organization
        </Button>
      </div>
      {selectedRecording && (
        <SplitGroupPane
          groupClassName={"[&&]:h-[calc(100vh-140px)]"}
          defaultSizes={["auto", 320]}
          rightPaneProps={{
            maxSize: 500,
            minSize: 320,
          }}
          leftPanelChildren={
            <div data-id="player" className="size-full overflow-auto p-6">
              {events && events.length > 0 && (
                <PPPlayer events={events} showCustomControls={false} showNativeControls />
              )}
              <div className="absolute inset-y-0 right-0 w-[2px] bg-panel-border" />
            </div>
          }
          rightPanelChildren={
            <div className="h-full overflow-auto">
              <div className="flex min-w-[300px] flex-col gap-3 p-5">
                <Card className="p-[5px]" {...props}>
                  <CardHeader className="flex flex-col px-0 pb-0 text-lg">
                    {aiSummary?.flagged && (
                      <div className="my-5 flex flex-col gap-2">
                        <Chip variant="solid" color="danger" className="m-auto text-lg">
                          This Recording was Flagged
                        </Chip>
                        <p className="text-sm text-foreground/80">{aiSummary.explanation}</p>
                        <Divider />
                      </div>
                    )}
                    Actions
                  </CardHeader>
                  <CardBody className="w-full gap-5 p-5">
                    <Button color="primary" onPress={onOpenModalHighlights}>
                      Add Highlight
                    </Button>
                    <Button onPress={() => alert("Coming soon")}>Manage Highlights</Button>
                  </CardBody>
                  <Divider />
                  <CardFooter>
                    <div className="w-full px-4 py-2">
                      {loadingAISummary && (
                        <div className="flex w-full flex-col gap-2">
                          <Skeleton className="mb-2 h-5 w-3/5 rounded-lg" />
                          <Skeleton className="h-4 w-full rounded-lg" />
                          <Skeleton className="h-4 w-full rounded-lg" />
                        </div>
                      )}
                      {!loadingAISummary && aiSummary && (
                        <div className="flex flex-col gap-2">
                          <h1 className="text-lg font-bold">AI Generated Summary</h1>
                          <p> {aiSummary.summary}</p>
                        </div>
                      )}
                      {!loadingAISummary && !aiSummary && (
                        <div className="flex flex-col gap-2">
                          <h1 className="text-lg font-bold">AI Generated Summary</h1>
                          <p>This recording does not have an AI generated summary.</p>
                        </div>
                      )}
                    </div>
                  </CardFooter>
                </Card>
              </div>
            </div>
          }
        />
      )}
    </div>
  );

  return (
    <AppLayout>
      <SplitGroupPane
        leftPaneProps={{ minSize: 320 }}
        defaultSizes={[320, "auto"]}
        leftPanelChildren={leftPanelChildren}
        rightPanelChildren={rightPanelChildren}
      />
      {uploadedMetadata && (
        <RecordingModal
          isOpen={isModalRecordingsOpen}
          onOpenChange={onModalRecordingsOpenChange}
          onSubmit={handleNewRecording}
          metadata={uploadedMetadata}
        />
      )}
      {selectedRecording && (
        <HighlightModal
          isOpen={isModalHighlightsOpen}
          onOpenChange={onModalHighlightsOpenChange}
          onSubmit={(startTime, endTime, title, summary, recording) =>
            handleNewHighlight(startTime, endTime, title, summary, recording as TRecording)
          }
          recording={selectedRecording}
        />
      )}
    </AppLayout>
  );
}
