import "split-pane-react/esm/themes/default.css";

import { FC, ComponentProps } from "react";

import SplitPaneOriginal, { Pane } from "split-pane-react";

export type SplitProps = ComponentProps<typeof SplitPaneOriginal>;

export type SplitPaneProps = Omit<SplitProps, "sashRender"> & {
  sashRender?: SplitProps["sashRender"];
};

export const SplitPane: FC<SplitPaneProps> = (props) => {
  const { sashRender, ...rest } = props;
  return <SplitPaneOriginal {...rest} sashRender={sashRender!} />;
};

export { Pane };
