declare global {
  export interface Window {
    __app: Record<string, unknown>;
  }
}

export const exportAPItoGlobal = (appExtension: Record<string, unknown>) => {
  window.__app = window.__app || {};

  const key = Object.keys(appExtension);

  for (let i = 0; i < key.length; i++) {
    const prop = key[i];
    const descriptor = Object.getOwnPropertyDescriptor(appExtension, prop);
    Object.defineProperty(window.__app, prop, descriptor!);
  }
};
