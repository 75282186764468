import { exportAPItoGlobal } from "@/helpers/expose-api";
import { onChange } from "@/helpers/on-change";
import { autorun, makeAutoObservable } from "mobx";

const prefersColorScheme = `(prefers-color-scheme: dark)`;

export class CSSMediaStore {
  userWantsDarkTheme?: boolean = undefined;

  prefersDarkThemeByDefault: boolean = window.matchMedia(prefersColorScheme).matches;

  constructor() {
    makeAutoObservable(this);
  }

  get theme() {
    if (typeof this.userWantsDarkTheme === "boolean") {
      return this.userWantsDarkTheme ? "dark" : "light";
    }
    return this.prefersDarkThemeByDefault ? "dark" : "light";
  }

  setDefaultDarkPreference(isDark: boolean) {
    this.prefersDarkThemeByDefault = isDark;
  }

  setUserThemeAsDark = (isDark: boolean) => {
    this.userWantsDarkTheme = isDark;
  };

  toggleTheme = () => {
    this.setUserThemeAsDark(this.theme === "dark" ? false : true);
  };
}

export const cssMediaStore = new CSSMediaStore();

export interface MediaQueryCallback {
  (matches: boolean): void;
}

const listenToMediaQueryChanges = (callback: MediaQueryCallback) => {
  if (!window.matchMedia) return () => ({});
  const listener = (e: MediaQueryListEvent) => {
    callback(e.matches);
  };

  if (window.matchMedia(prefersColorScheme).addEventListener) {
    window.matchMedia(prefersColorScheme).addEventListener("change", listener);

    return () => {
      window.matchMedia(prefersColorScheme).removeEventListener("change", listener);
    };
  }
  window.matchMedia(prefersColorScheme).addListener(listener);

  return () => {
    window.matchMedia(prefersColorScheme).removeListener(listener);
  };
};

listenToMediaQueryChanges((matches) => {
  cssMediaStore.setDefaultDarkPreference(matches);
});

const hydrate = () => {
  const uTheme = localStorage.getItem("__USER_THEME__");
  if (uTheme) {
    cssMediaStore.setUserThemeAsDark(uTheme === "dark");
  }
};

hydrate();

onChange(cssMediaStore, "userWantsDarkTheme", ({ userWantsDarkTheme }) => {
  if (userWantsDarkTheme === undefined) {
    localStorage.removeItem("__USER_THEME__");
  } else {
    localStorage.setItem("__USER_THEME__", userWantsDarkTheme ? "dark" : "light");
  }
});

autorun(() => {
  document.body.setAttribute("data-theme", cssMediaStore.theme);
  const html = document.documentElement;
  if (html) {
    html.classList.remove("light", "dark");
    html.classList.add(`${cssMediaStore.theme}`);
  }
});

exportAPItoGlobal({ cssMediaStore });
