import { Slider } from "@nextui-org/react";
import { useEffect, useState } from "react";

interface PlayerScrubberProps {
  playerProgress: number;
  onScrubRequest: (value: number) => void; // New prop
}

const PlayerScrubber: React.FC<PlayerScrubberProps> = ({ playerProgress, onScrubRequest }) => {
  const [progress, setProgress] = useState(playerProgress);
  const [isScrubbing, setIsScrubbing] = useState(false);

  useEffect(() => {
    if (!isScrubbing) {
      setProgress(playerProgress);
    }
  }, [playerProgress, isScrubbing]);

  function handleScrubRequest(value: number | number[]): void {
    setIsScrubbing(false); // Reset scrubbing state
    onScrubRequest(value as number);
  }

  function handleScrubChange(value: number | number[]): void {
    setIsScrubbing(true); // Set scrubbing state
    setProgress(value as number);
  }

  return (
    <Slider
      aria-label="progressbar"
      value={progress}
      onChangeEnd={handleScrubRequest}
      onChange={handleScrubChange}
      className="w-full"
      classNames={{
        track: "bg-default-500/30",
        thumb: "w-4 h-4 after:w-4 after:h-4 after:bg-primary",
      }}
      color="primary"
      defaultValue={33}
    />
  );
};

export default PlayerScrubber;
