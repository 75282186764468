import { LLMClassification, Recording } from "@/types/pptypes";
import { buildUrl, Endpoint } from "./fetch/endpoint";
import { fetchWithSession } from "./fetch/fetch-with-session";

export const getRecordingClassification = async (recording: Recording) => {
  try {
    const data = await fetchWithSession(
      buildUrl(Endpoint.ingest_recording, { recordingId: recording.id }),
      {
        method: "GET",
        body: null,
      },
    );
    return data as LLMClassification;
  } catch (error) {
    console.error(error);
    throw Error("Error fetching recording classification");
  }
};
