import { Profile } from "@/types/pptypes";
import { buildUrl, Endpoint } from "./fetch/endpoint";
import { fetchWithSession } from "./fetch/fetch-with-session";

export async function fetchProfileByUserId(userId: string) {
  try {
    // TODO: should this contain the id? or should you use supabase cookie to get the user id in the server side
    const data = await fetchWithSession(buildUrl(Endpoint.profile_by_user_id, { userId: userId }));
    return data as Profile;
  } catch (error) {
    console.error(error);
    throw Error("Error fetching profile by user id");
  }
}

export type UpdateDataPayload = {
  first_name: string;
  last_name: string;
  organization_id?: string;
};

export async function completeOnboarding(
  userId: string,
  firstName: string,
  lastName: string,
  organizationId?: string,
) {
  const updateData: UpdateDataPayload = {
    first_name: firstName,
    last_name: lastName,
  };

  if (organizationId) {
    updateData.organization_id = organizationId;
  }

  try {
    const data = await fetchWithSession(buildUrl(Endpoint.profile_by_user_id, { userId: userId }), {
      method: "PATCH",
      body: updateData,
    });
    return data as Profile;
  } catch (error) {
    console.error(error);
    throw Error("Error fetching profile by user id");
  }
}
