import { Organization } from "@/types/pptypes";
import { fetchWithSession } from "./fetch/fetch-with-session";
import { buildUrl, Endpoint } from "./fetch/endpoint";

export async function fetchAllOrganizations() {
  try {
    const data = await fetchWithSession(buildUrl(Endpoint.organizations));
    return data as Organization[];
  } catch (error) {
    console.error(error);
    throw Error("Error fetching organizations");
  }
}

export async function fetchMatchingOrganizations(domain: string) {
  try {
    const data = await fetchWithSession(
      buildUrl(Endpoint.organizations_by_domain, { domain: domain }),
    );
    if (!data) {
      return [];
    }
    return [data] as Organization[];
  } catch (error) {
    console.error(error);
    throw Error("Error fetching organizations by domain");
  }
}

export async function createOrganization(organization: Partial<Organization>) {
  try {
    const data = await fetchWithSession(buildUrl(Endpoint.organizations), {
      method: "POST",
      body: {
        name: organization.name,
        logo_url: organization.logo_url,
        domain_access: organization.domain_access,
        subscription_id: organization.subscription_id,
      },
    });
    return data as Organization;
  } catch (error) {
    console.error(error);
    throw Error("Error creating organization");
  }
}

export async function fetchOrganizationById(id: string) {
  try {
    const data = await fetchWithSession(buildUrl(Endpoint.organizations, { id: id }));
    return data as Organization;
  } catch (error) {
    console.error(error);
    throw Error("Error fetching organization by id");
  }
}
