"use client";

import React, { ReactElement } from "react";
import { Button } from "@nextui-org/react";
import { Icon } from "@iconify/react";

import { cn } from "@/config/cn";

export interface ListHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  make?: string;
  page?: number;
  onOpen?: () => void;
  onRightIconPress?: () => void;
  paginate?: (direction: number) => void;
  rightIcon?: string | ReactElement;
}

const ListHeader = React.forwardRef<HTMLInputElement, ListHeaderProps>(
  ({ title, rightIcon, page, paginate, onOpen, onRightIconPress, className, ...props }, ref) => {
    return (
      <header
        className={cn("flex w-full items-center justify-between px-3 py-3 sm:px-6", className)}
        {...props}
        ref={ref}
      >
        {page === 0 ? (
          <Button
            isIconOnly
            className={cn("flex text-default-500", {
              "sm:hidden": page === 0,
            })}
            size="sm"
            variant="light"
            onPress={onOpen}
          >
            <Icon height={24} icon="solar:hamburger-menu-outline" width={24} />
          </Button>
        ) : (
          <Button
            isIconOnly
            className="flex text-default-500 lg:hidden"
            size="sm"
            variant="light"
            onPress={() => paginate?.(-1)}
          >
            <Icon height={24} icon="solar:arrow-left-outline" width={24} />
          </Button>
        )}

        <div
          className={cn(
            "flex w-full items-center justify-center text-large font-bold text-foreground lg:justify-start",
            {
              "sm:justify-start": page === 0,
            },
          )}
        >
          <h2 className="text-large font-bold text-foreground">{title}</h2>
        </div>

        {rightIcon && (
          <Button
            isIconOnly
            className="ml-auto size-[28px] min-w-[28px] rounded-[6px] border-1 border-default-200 p-0 text-default-400"
            variant="bordered"
            onPress={onRightIconPress}
          >
            {typeof rightIcon === "string" ? (
              <img src={rightIcon} alt="button icon" className="size-full object-cover" />
            ) : (
              rightIcon
            )}
          </Button>
        )}
      </header>
    );
  },
);

ListHeader.displayName = "HighlightsHeader";

export default ListHeader;
