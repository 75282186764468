import { Card, CardBody, CardFooter, Chip, Input, Spinner, Button } from "@nextui-org/react";
import { Organization } from "@/types/pptypes";
import React from "react";

interface OnboardingCardProps {
  firstName: string;
  lastName: string;
  organizations: Organization[];
  isLoading: boolean;
  handleSubmit: (event: React.FormEvent) => void;
  setFirstName: (value: string) => void;
  setLastName: (value: string) => void;
}

const OnboardingCard: React.FC<OnboardingCardProps> = ({
  firstName,
  lastName,
  organizations,
  isLoading,
  handleSubmit,
  setFirstName,
  setLastName,
  ...props
}) => {
  return (
    <Card className="max-w-[480px] border-small border-default-100 p-3" shadow="sm" {...props}>
      <CardBody className="px-4 pb-1">
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col items-center justify-center gap-5">
            <div className="flex max-w-[80%] flex-col gap-1 text-center">
              <h1>Welcome to PathPilot</h1>
              <p className="text-default-500">Complete your Registration</p>
            </div>

            <Input
              required
              type="text"
              label="Name"
              placeholder="Add your first name"
              className="max-w-xs"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <Input
              required
              type="text"
              label="Lastname"
              placeholder="Add your lastname"
              className="max-w-xs"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />

            {organizations.length == 0 ? (
              <>
                <Chip color="warning" variant="dot">
                  No matching organization
                </Chip>
                <p className="text-center text-default-500">
                  Complete your info and we will get in touch soon to activate your organization
                  access
                </p>
              </>
            ) : (
              <>
                <Chip color="success" variant="dot">
                  {organizations[0].name}
                </Chip>
                <p className="">You will be joining this organization</p>
              </>
            )}
          </div>
          <CardFooter className="flex flex-col justify-between gap-2">
            {isLoading ? (
              <Spinner label="Loading Data" />
            ) : (
              <Button type="submit" color="primary">
                Confirm
              </Button>
            )}
          </CardFooter>
        </form>
      </CardBody>
    </Card>
  );
};

export default OnboardingCard;
