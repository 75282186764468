import { Organization } from "@/types/pptypes";
import SUOrganizationPicker from "./su-organization-picker";
import { useProfile } from "@/hooks/use-profile";
import { useEffect, useState } from "react";

import clsx from "clsx";

interface SUHomeControlsProps {
  currentOrganization?: Organization | null;
  handleOrganizationSelected: (organization: Organization) => void;
}

const SUHomeControls = ({
  handleOrganizationSelected,
  currentOrganization,
}: SUHomeControlsProps) => {
  const { profile } = useProfile();
  const [isSU, setIsSU] = useState(false);

  useEffect(() => {
    if (profile) {
      //TODO: do a backend call to authorize this
      setIsSU(profile.is_internal);
    }
  }, [profile]);

  return (
    <>
      {isSU && (
        <div
          className={clsx("border-b border-default-200/80 p-3", {
            "[&&]:px-6 [&&]:py-4": true,
          })}
        >
          <SUOrganizationPicker
            currentOrganization={currentOrganization}
            handleOrganizationSelected={handleOrganizationSelected}
          />
        </div>
      )}
    </>
  );
};
export default SUHomeControls;
