import { fetchAllOrganizations } from "@/api/services/organizations";
import { Organization } from "@/types/pptypes";
import { Select, SelectItem } from "@nextui-org/react";
import { useEffect, useState } from "react";

interface OrganizationPickerProps {
  handleOrganizationSelected: (organization: Organization) => void;
  currentOrganization?: Organization | null;
}

const OrganizationPicker = ({
  handleOrganizationSelected,
  currentOrganization,
}: OrganizationPickerProps) => {
  const [organizations, setOrganizations] = useState<Organization[]>([]);

  const handleSelectionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const id = e.target.value;
    if (id) {
      const org = organizations.find((org) => org.id === id);
      if (!org) {
        return;
      }
      handleOrganizationSelected(org);
    }
  };

  useEffect(() => {
    const fetchOrganizations = async () => {
      const data = await fetchAllOrganizations();
      setOrganizations(data);
    };

    fetchOrganizations();
  }, []);

  return (
    <Select
      variant="bordered"
      label="Select an Organization"
      className="w-full"
      selectedKeys={currentOrganization ? [currentOrganization.id] : []}
      onChange={handleSelectionChange}
    >
      {organizations.map((organization) => (
        <SelectItem key={organization.id}>{organization.name}</SelectItem>
      ))}
    </Select>
  );
};

export default OrganizationPicker;
