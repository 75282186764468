import { Subscription } from "@/types/pptypes";
import { buildUrl, Endpoint } from "./fetch/endpoint";
import { fetchWithSession } from "./fetch/fetch-with-session";

export async function getAllSubscriptions() {
  try {
    const data = await fetchWithSession(buildUrl(Endpoint.subscriptions));
    return data as Subscription[];
  } catch (error) {
    console.error(error);
    throw Error("Error fetching organizations");
  }
}
