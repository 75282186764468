import debounce from "lodash/debounce";
import { waitForIt } from "./wait-for-defined";

export const onWidthChange = (
  ref: React.RefObject<HTMLDivElement>,
  onSizeChange: (entry: DOMRectReadOnly) => void,
  options: { fireOnConnection?: boolean } = {},
) => {
  let prevWidth = -1;

  const raiseChange = (rect?: DOMRectReadOnly) => {
    if (rect?.width != null && rect.width !== prevWidth) {
      prevWidth = rect.width;
      onSizeChange(rect);
    }
  };

  const onChangeDebounced = debounce(raiseChange, 500);
  const obs = new ResizeObserver((entries) => {
    for (const entry of entries) {
      if (entry.contentRect) {
        onChangeDebounced(entry.contentRect);
      }
    }
  });

  const connectObs = async () => {
    await waitForIt(() => ref.current, {
      timeout: 5000,
      tryInterval: 100,
    });
    const elem = ref.current;
    if (!elem) return;

    obs.observe(elem);
    if (options.fireOnConnection) {
      raiseChange(elem?.getBoundingClientRect());
    }
  };

  connectObs();

  return () => {
    onChangeDebounced.cancel();
    obs.disconnect();
  };
};
