import HighlightsAdminPage from "@/pages/admin/highlights-admin";
import AdminIndexPage from "@/pages/admin/index";
import RegisterOrgPage from "@/pages/admin/register-org";
import HighlightDetailsPage from "@/pages/highlight-details";
import HighlightsPage from "@/pages/highlights";
import OnboardingPage from "@/pages/onboarding";
import { Route, Routes } from "react-router-dom";
import { NotFound } from "../components/not-found";

export const AuthenticatedContent = () => {
  return (
    <Routes>
      <Route element={<HighlightsPage />} path="/" />
      <Route element={<OnboardingPage />} path="/onboarding" />
      <Route element={<HighlightDetailsPage />} path="/highlights/:id" />
      {/* admin routes */}
      <Route element={<AdminIndexPage />} path="/admin" />
      <Route element={<RegisterOrgPage />} path="/register-org" />
      <Route element={<HighlightsAdminPage />} path="admin/highlights" />
      {/*fallback - not found */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
