import { makeAutoObservable } from "mobx";
import { profileStore } from "./profileStore";
import { siteConfig } from "@/config/site";

export class NavigationStore {
  constructor() {
    makeAutoObservable(this);
  }

  get availableRoutes() {
    const profile = profileStore.profile;
    if (!profile) {
      return [];
    }

    if (!profile.is_internal) {
      return siteConfig.navItems.filter((item) => item.access !== "internal");
    }

    return siteConfig.navItems;
  }

  get availableMobileRoutes() {
    const profile = profileStore.profile;
    if (!profile) {
      return [];
    }

    if (!profile.is_internal) {
      return siteConfig.navMenuItems.filter((item) => item.access !== "internal");
    }
    return siteConfig.navMenuItems;
  }
}

export const navigationStore = new NavigationStore();
