import { TRecording } from "@/api/services/recordings";
import { cn } from "@/config/cn";
import { formatTimestamp } from "@/helpers/date";
import { Recording } from "@/types/pptypes";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Button, Listbox, ListboxItem, ScrollShadow, Spinner } from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import { PlusIcon } from "../icons";
import ListHeader from "../list-header";

export type RecordingsListProps = React.HTMLAttributes<HTMLDivElement> & {
  recordings: TRecording[];
  fetchMore: () => void;
  onRecordingClick: (recording: Recording) => void;
  onRightIconPress: () => void;
  hasMore?: boolean;
  paginate?: (direction: number) => void;
};

const RecordingsList = React.forwardRef<HTMLDivElement, RecordingsListProps>(
  (
    { recordings, fetchMore, onRecordingClick, onRightIconPress, hasMore = false, ...props },
    ref,
  ) => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      setLoading(false);
    }, [recordings]);

    async function fetchMoreRecordings() {
      setLoading(true);
      fetchMore();
    }

    return (
      <div ref={ref} {...props}>
        <div className="h-dvh w-full overflow-visible">
          <ListHeader
            title={`Recordings`}
            rightIcon={<PlusIcon />}
            className="hidden sm:flex"
            onRightIconPress={onRightIconPress}
          />
          {/* TODO: remove the calc using flex-1 */}
          <ScrollShadow className="flex h-full max-h-[calc(100vh-300px)] flex-col gap-6 overflow-y-auto px-3">
            <Listbox
              aria-label="Recording List"
              classNames={{
                base: "p-0",
              }}
              items={recordings}
              variant="solid"
              itemClasses={{
                base: "data-[selected=true]:bg-primary data-[selected=true]:text-primary-foreground data-[selectable=true]:focus:bg-primary data-[selectable=true]:text-primaray-foreground",
              }}
              hideSelectedIcon
              selectionMode="single"
              bottomContent={
                hasMore ? (
                  <div className="flex w-full justify-center">
                    <Button isDisabled={loading} variant="flat" onPress={fetchMoreRecordings}>
                      {loading && <Spinner color="primary" size="sm" />}
                      Load More
                    </Button>
                  </div>
                ) : null
              }
            >
              {(item: TRecording) => (
                <ListboxItem
                  key={item.id}
                  className={cn("mb-2 px-4", {
                    "bg-default-100": true,
                  })}
                  textValue={"List of Highlights"}
                  onPress={() => onRecordingClick(item)}
                >
                  <div className="flex items-center gap-2 py-1 text-left">
                    <Icon
                      className="flex-none text-default-400 group-data-[selected=true]:text-white/60 [&>g]:stroke-[2px]"
                      icon="solar:video-frame-2-bold"
                      width={15}
                    />
                    <div className="ml-2 min-w-0 flex-1">
                      <div className="flex w-full flex-row justify-between">
                        <div className="text-small font-semibold text-default-foreground group-data-[selected=true]:text-primary-foreground">
                          {`${item.id}`}
                        </div>
                        <div className="text-small text-default-400 group-data-[selected=true]:text-white/60">
                          {`${item.highlights_count} highlights`}
                        </div>
                      </div>
                      <div className="truncate text-small text-default-500 group-data-[selected=true]:text-white/80">
                        {item.ext_user_info}
                      </div>
                      <div className="text-xs text-default-400 group-data-[selected=true]:text-white/60">
                        Recorded: {formatTimestamp(item.ext_timestamp)}
                      </div>
                    </div>
                  </div>
                </ListboxItem>
              )}
            </Listbox>
          </ScrollShadow>
        </div>
      </div>
    );
  },
);

RecordingsList.displayName = "RecordingInbox";

export default RecordingsList;
