import { Recording } from "@/types/pptypes";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import { useState } from "react";

interface HighlightModalProps {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  onSubmit: (
    startTime: number,
    endTime: number,
    title: string,
    summary: string,
    recording: Recording,
  ) => void;
  recording: Recording;
}

const HighlightModal: React.FC<HighlightModalProps> = ({
  isOpen,
  onOpenChange,
  onSubmit,
  recording,
}) => {
  const [startMinutes, setStartMinutes] = useState("00");
  const [startSeconds, setStartSeconds] = useState("00");
  const [endMinutes, setEndMinutes] = useState("00");
  const [endSeconds, setEndSeconds] = useState("00");
  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");

  const handleSubmit = async () => {
    const startTime = Number(startMinutes) * 60 + Number(startSeconds);
    const endTime = Number(endMinutes) * 60 + Number(endSeconds);

    // Validation
    if (isNaN(startTime) || isNaN(endTime) || startTime < 0 || endTime < 0) {
      alert("Start and end times must be valid numbers.");
      return;
    }
    if (endTime <= startTime) {
      alert("End time must be greater than start time by at least one second.");
      return;
    }
    if (!title.trim() || !summary.trim()) {
      alert("Title and summary cannot be empty.");
      return;
    }

    await onSubmit(startTime, endTime, title, summary, recording);
    onOpenChange(false);

    // Reset fields
    setStartMinutes("00");
    setStartSeconds("00");
    setEndMinutes("00");
    setEndSeconds("00");
    setTitle("");
    setSummary("");
  };

  return (
    <Modal isOpen={isOpen} onOpenChange={onOpenChange} placement="top-center">
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1">{`Add Highlight to Recording ${recording.id}`}</ModalHeader>
            <ModalBody>
              <div className="flex gap-2">
                <Input
                  required
                  id="startMinutes"
                  autoFocus
                  label="Start Minutes"
                  placeholder="MM"
                  variant="bordered"
                  value={startMinutes}
                  type="number"
                  onChange={(e) => setStartMinutes(e.target.value)}
                />
                <Input
                  required
                  id="startSeconds"
                  label="Start Seconds"
                  placeholder="SS"
                  variant="bordered"
                  value={startSeconds}
                  type="number"
                  onChange={(e) => setStartSeconds(e.target.value)}
                />
              </div>
              <div className="flex gap-2">
                <Input
                  required
                  id="endMinutes"
                  label="End Minutes"
                  placeholder="MM"
                  variant="bordered"
                  value={endMinutes}
                  type="number"
                  onChange={(e) => setEndMinutes(e.target.value)}
                />
                <Input
                  required
                  id="endSeconds"
                  label="End Seconds"
                  placeholder="SS"
                  variant="bordered"
                  value={endSeconds}
                  type="number"
                  onChange={(e) => setEndSeconds(e.target.value)}
                />
              </div>
              <Input
                required
                id="title"
                label="Highlight Title"
                placeholder="Enter a title for the highlight. Be brief (1 sentence)"
                variant="bordered"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <Input
                required
                id="summary"
                label="Highlight Description"
                placeholder="Enter a description of events."
                variant="bordered"
                value={summary}
                onChange={(e) => setSummary(e.target.value)}
              />
            </ModalBody>
            <ModalFooter>
              <Button color="danger" variant="flat" onPress={onClose}>
                Cancel
              </Button>
              <Button color="primary" onPress={handleSubmit}>
                Submit
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default HighlightModal;
