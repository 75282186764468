"use client";

import { formatTimestamp } from "@/helpers/date";
import { isEmail } from "@/helpers/email";
import { Highlight, HighlightFeedback, Organization, Recording } from "@/types/pptypes";
import { Icon } from "@iconify/react/dist/iconify.js";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Image,
  Link,
  Textarea,
} from "@nextui-org/react";
import React, { forwardRef, useState } from "react";

export type HighlightInfoProps = React.HTMLAttributes<HTMLDivElement> & {
  highlight: Highlight;
  organization: Organization;
  recording?: Recording;
  lastFeedback: HighlightFeedback | null;
  onFeedback: (score: number, summary?: string) => void;
  paginate?: (page: number) => void;
  toggleMessagingProfileSidebar?: () => void;
};

const HighlightInfo = forwardRef<HTMLDivElement, HighlightInfoProps>(
  ({ highlight, organization, recording, lastFeedback, onFeedback }, ref) => {
    const [feedbackSummary, setFeedbackSummary] = useState<string>("");
    const [showFeedbackSummary, setShowFeedbackSummary] = useState<boolean>(false);
    console.log(organization);
    function sendFeedbackSummary() {
      if (!lastFeedback || feedbackSummary === "") {
        return;
      }
      onFeedback(lastFeedback.score, feedbackSummary);
      setShowFeedbackSummary(false);
      setFeedbackSummary("");
    }
    return (
      <div ref={ref} className="flex size-full  items-start justify-center">
        <Card className="my-2 mr-2 w-full min-w-[300px] lg:my-10">
          <CardHeader className="flex flex-col gap-1">
            <div className="flex w-full flex-row gap-3">
              {organization.logo_url && (
                <Image
                  alt="Company Logo"
                  height={40}
                  radius="sm"
                  src={organization.logo_url}
                  width={40}
                />
              )}
              <div className="flex flex-col">
                <p>{organization.name}</p>
                <p className="text-small text-default-500">
                  {recording?.ext_user_info && isEmail(recording.ext_user_info)
                    ? recording.ext_user_info
                    : "Anonymous User"}
                </p>
              </div>
            </div>
            <div className="flex w-full flex-row justify-between">
              <p className="max-w-[90%] text-small text-default-400">
                {`Recorded ${formatTimestamp(recording?.ext_timestamp)}`}
              </p>
              <div className="text-small text-default-400 group-data-[selected=true]:text-white/60">
                {`${highlight.end_time_in_s - highlight.start_time_in_s}s`}
              </div>
            </div>
            <div className="flex w-full flex-row justify-between">
              <p className="max-w-[90%] text-small text-default-400">
                {`Generated ${formatTimestamp(highlight.created_at)}`}
              </p>
            </div>
          </CardHeader>
          <Divider />
          <CardBody>
            <div>
              <p className="py-2 text-left text-small text-foreground">{highlight.summary}</p>
            </div>
          </CardBody>
          <Divider />
          <CardFooter className="flex flex-col gap-2">
            <h2 className="text-small font-medium">How was this highlight?</h2>
            <div className="flex flex-row">
              <Button
                className="mr-2 w-1/2"
                variant={lastFeedback?.score === 1 ? "solid" : "bordered"}
                color="success"
                onClick={() => onFeedback(1)}
              >
                <Icon icon="solar:smile-circle-outline" width={30} />
                Great!
              </Button>
              <Button
                className="ml-2 w-1/2"
                variant={lastFeedback?.score === 0 ? "solid" : "bordered"}
                color="danger"
                onClick={() => onFeedback(0)}
              >
                <Icon icon="solar:sad-circle-outline" width={30} />
                Mehh..
              </Button>
            </div>
            <div className="flex flex-col">
              <Link
                className="flex flex-row"
                onClick={() => {
                  setShowFeedbackSummary(true);
                }}
                showAnchorIcon={true}
                anchorIcon={<Icon icon="solar:pen-2-bold" width={15} className="text-foreground" />}
              >
                <h2 className="text-small font-medium">Feedback?</h2>
              </Link>
            </div>
            {showFeedbackSummary && (
              <Textarea
                value={feedbackSummary}
                onChange={(e) => setFeedbackSummary(e.target.value)}
                placeholder="What can we do to improve this highlight?"
                className="w-full"
                endContent={
                  <Button
                    variant="solid"
                    isIconOnly
                    size="sm"
                    disabled={!feedbackSummary}
                    onPress={sendFeedbackSummary}
                  >
                    <Icon icon="solar:map-arrow-right-bold" width={15} />
                  </Button>
                }
              />
            )}
          </CardFooter>
        </Card>
      </div>
    );
  },
);

HighlightInfo.displayName = "HighlightDetails";

export default HighlightInfo;
