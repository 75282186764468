export interface PromiseFn<T> {
  (...args: unknown[]): Promise<T>;
}

export interface ClearableCachedPromisedFn<T> extends PromiseFn<T> {
  clear(): void;
}

export const withCachedPromise = <T>(fn: PromiseFn<T>) => {
  let p: Promise<T> | undefined;

  const ret: ClearableCachedPromisedFn<T> = (...args: unknown[]) => {
    if (p) {
      return p;
    }

    p = fn(...args);

    p?.catch(() => {
      p = undefined;
    });

    return p;
  };

  ret.clear = () => {
    p = undefined;
  };

  return ret;
};
