import posthog from "posthog-js";

// TODOO: this seems to be asynchrous. it might need to be awaited
// before we can use the ph object
posthog.init(import.meta.env.VITE_POSTHOG_KEY, {
  loaded: function (ph) {
    if (import.meta.env.VITE_ENVIRONMENT == "development") {
      ph.opt_out_capturing(); // opts a user out of event capture
      ph.set_config({ disable_session_recording: true });
    }
  },
});

export const ph = posthog;
