"use client";

import React from "react";

export interface HighlightsHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  rightIcon?: string;
  page?: number;
  onOpen?: () => void;
  paginate?: (direction: number) => void;
}

const HighlightsHeader = React.forwardRef<HTMLInputElement, HighlightsHeaderProps>(
  ({ title, rightIcon }, ref) => {
    return (
      <div ref={ref} className="flex w-full gap-3">
        <div className="size-12 rounded-[50%] bg-default-100 p-1">
          <div
            style={{ backgroundImage: rightIcon ? `url(${rightIcon})` : undefined }}
            className="size-full bg-contain bg-no-repeat"
          />
        </div>
        <div className="flex flex-1 items-center">
          <h2 className="text-large font-bold text-foreground">{title}</h2>
        </div>
      </div>
    );
  },
);

HighlightsHeader.displayName = "HighlightsHeader";

export default HighlightsHeader;
