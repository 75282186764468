import { HighlightFeedback } from "@/types/pptypes";
import { buildUrl, Endpoint } from "./fetch/endpoint";
import { fetchWithSession } from "./fetch/fetch-with-session";

export const insertHighlightFeedback = async (feedback: Partial<HighlightFeedback>) => {
  try {
    const data = await fetchWithSession(
      buildUrl(Endpoint.feedback_highlight, { highlightId: feedback.highlight_id! }),
      {
        method: "POST",
        body: feedback,
      },
    );
    return data as HighlightFeedback;
  } catch (error) {
    console.error(error);
    throw Error("Error creating feedback");
  }
};

export const fetchHighlightFeedback = async (highlightId: string) => {
  try {
    const data = await fetchWithSession(
      buildUrl(Endpoint.feedback_highlight, { highlightId: highlightId }),
    );
    console.log(data);
    return data as HighlightFeedback | null;
  } catch (error) {
    console.error(error);
    throw Error("Error fetching highlight feedback");
  }
};
