import { Icon } from "@iconify/react";
import clsx from "clsx";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Listbox,
  ListboxItem,
  ScrollShadow,
  Spinner,
} from "@nextui-org/react";
import React, { useCallback, useEffect, useState } from "react";

import { fetchHighlightsByOrganization } from "@/api/services/highlights";

import { formatTimestamp } from "@/helpers/date";
import { Highlight, Organization, Recording } from "@/types/pptypes";
import Fuse from "fuse.js";
import { get } from "lodash";
import HighlightsHeader from "./highlights-header";
import { useViewportBreakpoints } from "@/helpers/screen";

const PAGE_SIZE = 10;

export type HighlightsInboxProps = React.HTMLAttributes<HTMLDivElement> & {
  organization: Organization;
  selectedHighlight?: Highlight | null;
  onHighlightClick: (highlight: Highlight, recording: Recording) => void;
  page?: number;
  paginate?: (direction: number) => void;
};

export type HighlightWithRecording = Highlight & {
  recording: Recording;
};

const HighlightsInbox = React.forwardRef<HTMLDivElement, HighlightsInboxProps>(
  (
    { organization, selectedHighlight, onHighlightClick, page, paginate, className, ...rest },
    ref,
  ) => {
    const [highlights, setHighlights] = useState<HighlightWithRecording[]>([]);
    const [filteredHighlights, setFilteredHighlights] = useState<HighlightWithRecording[]>([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [sort, setSort] = useState<string>("c");
    const [triggerHeadFetch, setTriggerHeadFetch] = useState(true);

    const { hMatches } = useViewportBreakpoints();

    useEffect(() => {
      const fuse = new Fuse(highlights, {
        keys: ["title", "summary", "recording.ext_user_info"],
        threshold: 0,
        ignoreLocation: true,
      });
      if (searchQuery) {
        const result = fuse.search(searchQuery).map(({ item }) => item);
        setFilteredHighlights(result);
      } else {
        setFilteredHighlights(highlights);
      }
    }, [searchQuery, highlights]);

    useEffect(() => {
      setLoading(true);
      if (organization) {
        //cleanup
        setSelectedKeys([]);
        setHighlights([]);
        setCurrentPage(1);
        setHasMore(true);
        setSearchQuery("");
        setSort("c");
        setTriggerHeadFetch(true);
      }
    }, [organization]);

    const fetchNextPageHighlightsByOrgId = useCallback(() => {
      setLoading(true);
      fetchHighlightsByOrganization(organization.id, currentPage, PAGE_SIZE, sort)
        .then((data) => {
          setHighlights([...highlights, ...data.highlights]);
          setCurrentPage(currentPage + 1);
          setHasMore(data.currentPage < data.totalPages);

          if (data.count != null && data.count > 0 && !selectedHighlight) {
            const firstHighlight: HighlightWithRecording = get(data.highlights, "[0]");

            // TODO: clean the logic here to handle this from the store
            // do not select an element on mobile view
            if (!hMatches.md) return;

            setSelectedKeys([firstHighlight.id as string]);

            onHighlightClick(firstHighlight, firstHighlight.recording);
          }

          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setHighlights([]);
          setCurrentPage(1);
          setHasMore(false);
          setLoading(false);
          setTriggerHeadFetch(false);
        });
    }, [
      organization,
      currentPage,
      highlights,
      selectedHighlight,
      onHighlightClick,
      hMatches.md,
      sort,
    ]);

    useEffect(() => {
      if (triggerHeadFetch) {
        fetchNextPageHighlightsByOrgId();
        setTriggerHeadFetch(false);
      }
    }, [triggerHeadFetch, fetchNextPageHighlightsByOrgId]);

    useEffect(() => {
      if (!selectedHighlight) {
        return;
      }

      setSelectedKeys((prev) => {
        if (get(prev, "[0].id") !== selectedHighlight) {
          return [selectedHighlight.id as string];
        }
        return prev;
      });
    }, [selectedHighlight]);

    function handleSortChange(newSort: string) {
      setSort(newSort);
      setSelectedKeys([]);
      setHighlights([]);
      setCurrentPage(1);
      setHasMore(true);
      setSearchQuery("");
      setTriggerHeadFetch(true);
    }

    return (
      <div ref={ref} className={clsx(className, "flex min-h-0 flex-col")} {...rest}>
        <div
          className={clsx("border-b border-default-200/80", {
            "[&&]:px-6 [&&]:py-4": true,
          })}
        >
          <HighlightsHeader
            title={`Highlights - ${organization.name}`}
            rightIcon={organization.logo_url}
            page={page}
            paginate={paginate}
          />
        </div>
        <div className={clsx("p-3", { "[&&]:px-6": true })}>
          <div className="flex flex-row gap-2">
            <div className="flex-1">
              <Input
                aria-label="Search"
                labelPlacement="outside"
                placeholder="Search..."
                className="w-full"
                radius="md"
                startContent={
                  <Icon
                    className="text-default-500 [&>g]:stroke-[2px]"
                    icon="solar:magnifer-linear"
                    width={18}
                  />
                }
                variant="bordered"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onClear={() => setSearchQuery("")}
              />
            </div>
            <div className="size-10">
              <Dropdown>
                <DropdownTrigger>
                  <Button variant="light" isIconOnly>
                    <Icon icon="solar:sort-bold" width={25} />
                  </Button>
                </DropdownTrigger>
                <DropdownMenu
                  aria-label="Static Actions"
                  selectionMode="single"
                  selectedKeys={[sort]}
                  onSelectionChange={(e) => handleSortChange(e.currentKey as string)}
                >
                  <DropdownItem key="c">Sort by highlight creation</DropdownItem>
                  <DropdownItem key="rc">Sort by recording capture</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        </div>
        <ScrollShadow
          className={clsx("flex min-h-0 flex-1 flex-col gap-6 overflow-y-auto p-3", {
            "[&&]:px-6": true,
          })}
        >
          <Listbox
            classNames={{
              base: "p-0 [&&_ul]:gap-3",
            }}
            selectedKeys={selectedKeys}
            items={filteredHighlights}
            variant="solid"
            itemClasses={{
              base: "data-[selected=true]:bg-primary data-[selected=true]:text-primary-foreground data-[selectable=true]:focus:bg-primary data-[selectable=true]:text-primaray-foreground",
            }}
            hideSelectedIcon
            selectionMode="single"
            bottomContent={
              hasMore ? (
                <div className="flex w-full justify-center">
                  <Button
                    isDisabled={loading}
                    variant="flat"
                    onPress={fetchNextPageHighlightsByOrgId}
                  >
                    {loading && <Spinner color="primary" size="sm" />}
                    Load More
                  </Button>
                </div>
              ) : null
            }
          >
            {(item: HighlightWithRecording) => (
              <ListboxItem
                key={item.id ?? ""}
                className="bg-default-100"
                textValue={"List of Highlights"}
                onPress={() => onHighlightClick(item, item.recording)}
              >
                <div className="flex flex-row gap-2 py-2 pl-1 text-left">
                  <div className="flex size-10 items-center justify-center">
                    <Icon
                      className="text-default-400 group-data-[selected=true]:text-white/60 [&>g]:stroke-[2px]"
                      icon="solar:video-frame-2-bold"
                      width={25}
                    />
                  </div>

                  <div className="ml-2 min-w-0 flex-1 pr-3">
                    <div>
                      <h3 className="mb-2 truncate text-[16px] font-semibold text-default-foreground group-data-[selected=true]:text-primary-foreground">
                        {`${item.title}`}
                      </h3>
                    </div>
                    <div className="mb-2 truncate text-xs text-default-500 group-data-[selected=true]:text-white/80">
                      {item.recording.ext_user_info}
                    </div>
                    <div className="flex w-full flex-row justify-between">
                      <div className="text-xs text-default-400 group-data-[selected=true]:text-white/60">
                        {sort === "rc"
                          ? `recorded ${formatTimestamp(item.recording.ext_timestamp)}`
                          : `created ${formatTimestamp(item.created_at)}`}
                      </div>
                      <div className="text-xs text-default-400 group-data-[selected=true]:text-white/60">
                        {`${item.end_time_in_s - item.start_time_in_s}s`}
                      </div>
                    </div>
                  </div>
                </div>
              </ListboxItem>
            )}
          </Listbox>
        </ScrollShadow>
      </div>
    );
  },
);

HighlightsInbox.displayName = "HighlightsInbox";

export default HighlightsInbox;
