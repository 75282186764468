import {
  Button,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownSection,
  DropdownItem,
  Avatar,
  User as UserItem,
  AvatarIcon,
} from "@nextui-org/react";

import { Profile } from "@/types/pptypes";
import { authStore } from "@/stores/authStore";

export const ProfileAvatar = ({ profile }: { profile: Profile | null }) => {
  return (
    <>
      {!profile ? (
        <Avatar icon={<AvatarIcon />} size="sm" />
      ) : (
        <Dropdown showArrow placement="bottom-start">
          <DropdownTrigger>
            <Button disableRipple isIconOnly className="-mr-1" radius="full" variant="light">
              <Avatar icon={<AvatarIcon />} size="sm" />
            </Button>
          </DropdownTrigger>
          <DropdownMenu aria-label="Custom item styles" disabledKeys={["profile"]}>
            <DropdownSection showDivider aria-label="Profile & Actions">
              <DropdownItem
                key="profile"
                isReadOnly
                className="h-14 gap-2 opacity-100"
                textValue="Signed in as"
              >
                <UserItem
                  avatarProps={{
                    size: "sm",
                    imgProps: {
                      className: "transition-none",
                    },
                  }}
                  classNames={{
                    name: "text-default-600",
                    description: "text-default-500",
                  }}
                  description={
                    profile.organization
                      ? `${profile.organization.name} Member`
                      : "Needs Onboarding"
                  }
                  name={profile.display_name ?? `User`}
                />
              </DropdownItem>
              <DropdownItem key="settings">Settings</DropdownItem>
            </DropdownSection>

            <DropdownSection aria-label="Help & Feedback">
              <DropdownItem key="help_and_feedback">Help & Feedback</DropdownItem>
              <DropdownItem key="logout" onClick={authStore.logout}>
                Log Out
              </DropdownItem>
            </DropdownSection>
          </DropdownMenu>
        </Dropdown>
      )}
    </>
  );
};
