import { appMediator } from "@/helpers/app-mediator";
import { exportAPItoGlobal } from "@/helpers/expose-api";
import { supabaseClient } from "@/helpers/supabase-client";
import { withCachedPromise } from "@/helpers/with-cache-promise";
import { Session } from "@supabase/supabase-js";
import { makeAutoObservable, runInAction } from "mobx";
import { createRequestor } from "mobx-requestor";

const getSession = async () => {
  const { data, error } = await supabaseClient.auth.getSession();
  if (error) {
    throw new Error(error.message);
  }
  return data?.session as Session;
};

const logout = async () => {
  const { error } = await supabaseClient.auth.signOut();
  if (error) {
    throw error;
  }
};

export class AuthStore {
  authToken?: string = undefined;

  rq = createRequestor({
    call: getSession,
  });

  rqLogout = createRequestor({
    call: logout,
  });

  clearSessionData = () => {
    this.authToken = undefined;
    this.rq.clearErrorAndResponse();
  };

  constructor() {
    supabaseClient.auth.onAuthStateChange((event) => {
      if (event === "SIGNED_OUT") {
        this.clearSessionData();
      }
    });

    makeAutoObservable(this);
  }

  get isLoggedIn() {
    return !!this.authToken;
  }

  get loading() {
    return this.rq.loading;
  }

  get session() {
    return this.rq.response;
  }

  getSession = async () => {
    if (!this.session) {
      await this.fetchSession();
    }
    return this.session;
  };

  fetchSession = withCachedPromise(async () => {
    await this.rq.execCall();
    runInAction(() => {
      if (!this.rq.response) {
        return;
      }
      const { access_token, user } = this.rq.response;
      this.authToken = access_token;
      if (user) {
        appMediator.fire("onSessionReceived", { user });
      }
    });
  });

  logout = async () => {
    await this.rqLogout.execCall();
  };
}

export const authStore = new AuthStore();

exportAPItoGlobal({ authStore });
