// TODO: check what was needed for
// export function withSessionCache(fn: Function, keyArgIndex: number) {
//   return async function(...args: any[]) {
//       const key = args[keyArgIndex];
//       const cachedValue = sessionStorage.getItem(key);

//       if (cachedValue !== null) {
//           try {
//                 console.log("Sessioncache request " + cachedValue);
//               return JSON.parse(cachedValue);
//           } catch (e) {
//               console.error('Failed to parse cached value', e);
//           }
//       }

//       const result = await fn(...args);

//       try {
//           sessionStorage.setItem(key, JSON.stringify(result));
//       } catch (e) {
//           console.error('Failed to cache result', e);
//       }

//       return result;
//   };
// }

export function clearSessionCache() {
  sessionStorage.clear();
}
