import { supabaseClient } from "@/helpers/supabase-client";
import { fetchJSON } from "./fetch-json";
import { FetchURL, SerializeBodyProps } from "./fetch-types";

export async function fetchWithSession<T, K = never>(
  url: FetchURL,
  params?: SerializeBodyProps<K>,
): Promise<T> {
  return supabaseClient.auth.getSession().then(({ data: { session } }) => {
    const headers = {
      ...params?.headers,
      Authorization: `Bearer ${session?.access_token}`,
    };
    return fetchJSON<T, K>(url, { ...params, headers });
  });
}
