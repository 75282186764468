import { formatDistanceToNow } from "date-fns";

export const formatTimestamp = (timestamp: number | string | undefined): string => {
  if (!timestamp) return "--";
  try {
    if (typeof timestamp !== "number") {
      timestamp = Number(timestamp) || timestamp;
    }
    return `${formatDistanceToNow(new Date(timestamp))} ago`;
  } catch (error) {
    console.error("Error formatting timestamp:", timestamp, error);
    return "--";
  }
};
