import { Link } from "react-router-dom";
import {
  Navbar as NextUINavbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  NavbarMenuToggle,
  NavbarMenu,
  NavbarMenuItem,
  Spinner,
} from "@nextui-org/react";

import { link as linkStyles } from "@nextui-org/theme";
import clsx from "clsx";

import { siteConfig } from "@/config/site.ts";
import { ThemeSwitch } from "@/components/theme-switch";
import { FullLogo } from "@/components/icons";
import { ProfileAvatar } from "@/components/profile-avatar";
import { profileStore } from "@/stores/profileStore";
import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { navigationStore } from "@/stores/navigationStore";
import { useViewportBreakpoints } from "@/helpers/screen";

export const Navbar = observer(() => {
  const { profile, profileLoading } = profileStore;
  const { hMatches } = useViewportBreakpoints();

  useEffect(() => {
    profileStore.fetchProfile();
  }, []);

  return (
    <NextUINavbar maxWidth="full" className="flex items-center justify-between gap-4">
      <NavbarContent className="basis-1/5 sm:basis-full" justify="start">
        <NavbarBrand className="max-w-fit gap-3">
          <Link className="flex items-center justify-start gap-1" color="foreground" to="/">
            <FullLogo className="mt-[6px]" />
          </Link>
        </NavbarBrand>
        {hMatches.md && (
          <div className="ml-2 flex justify-start gap-4">
            {profileLoading && (
              <div className="flex w-full items-center justify-center">
                <Spinner title="Loading..." size="sm" />
              </div>
            )}
            {navigationStore.availableRoutes.map((item) => (
              <NavbarItem key={item.href}>
                <Link
                  className={clsx(
                    linkStyles({ color: "foreground" }),
                    "data-[active=true]:font-medium data-[active=true]:text-primary",
                  )}
                  color="foreground"
                  to={item.href}
                >
                  {item.label}
                </Link>
              </NavbarItem>
            ))}
          </div>
        )}
      </NavbarContent>

      {hMatches.md && (
        <NavbarContent justify="end">
          {profile && (
            <NavbarItem className="flex">
              <ProfileAvatar profile={profile} />
            </NavbarItem>
          )}
          <ThemeSwitch />
        </NavbarContent>
      )}
      {!hMatches.md && (
        <>
          <NavbarMenuToggle />
          <NavbarMenu>
            <div className="flex w-full items-center justify-end">
              {profile && (
                <NavbarMenuItem>
                  <div className="size-[40px]">
                    <ProfileAvatar profile={profile} />
                  </div>
                </NavbarMenuItem>
              )}
              <NavbarMenuItem>
                <ThemeSwitch />
              </NavbarMenuItem>
            </div>
            <div className="mx-4 mt-2 flex flex-col gap-2">
              {profileLoading && (
                <div className="flex w-full items-center justify-center">
                  <Spinner title="Loading..." />
                </div>
              )}
              {navigationStore.availableMobileRoutes.map((item, index) => (
                <NavbarMenuItem key={`${item}-${index}`}>
                  <Link
                    color={
                      index === 2
                        ? "primary"
                        : index === siteConfig.navMenuItems.length - 1
                          ? "danger"
                          : "foreground"
                    }
                    to={item.href}
                  >
                    {item.label}
                  </Link>
                </NavbarMenuItem>
              ))}
            </div>
          </NavbarMenu>
        </>
      )}
    </NextUINavbar>
  );
});
