import { fetchHighlightFeedback, insertHighlightFeedback } from "@/api/services/feedback";
import { fetchHighlightById } from "@/api/services/highlights";
import { fetchFileFromSupabase } from "@/api/services/recordings";
import HighlightInfo from "@/components/highlights/highlight-info";
import PPPlayer from "@/components/player/pp-player";
import { SplitGroupPane } from "@/components/split-group-pane";
import { useProfile } from "@/hooks/use-profile";
import { AppLayout } from "@/layouts/default";
import { Highlight, HighlightFeedback, Recording, RecordingEvent } from "@/types/pptypes";
import { Spinner } from "@nextui-org/react";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export default function HighlightDetails() {
  const { id } = useParams<{ id: string }>();
  const [highlight, setHighlight] = useState<Highlight | null>(null);
  const [highlightFeedback, setHighlightFeedback] = useState<HighlightFeedback | null>(null);
  const [recording, setRecording] = useState<Recording | null>(null);
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState<RecordingEvent[]>([]);
  const navigate = useNavigate(); // Initialize useNavigate
  const { profile } = useProfile();
  const location = useLocation();

  // Update currentOrganization when profile changes
  useEffect(() => {
    if (profile) {
      if (!profile.organization) {
        navigate("/onboarding"); // Redirect to onboarding page
      }
    }
  }, [profile, navigate]);

  useEffect(() => {
    async function fetchData() {
      if (!profile)
        return; //wait for profile
      else {
        if (!profile.organization) {
          // Redirect to onboarding page with current URL
          navigate("/onboarding", { state: { redirect: location.pathname } });
          return;
        }
      }
      try {
        const data = await fetchHighlightById(id ?? "");
        console.log("data", data);
        if (!data) {
          navigate("/not-found");
        }
        setHighlight(data);
        const recording: Recording = data.recording;
        setRecording(recording);

        if (!recording.json) {
          const jsonContent = await fetchFileFromSupabase(recording);
          const parsedEvents = JSON.parse(jsonContent);
          setEvents(parsedEvents.data.snapshots);
        } else {
          const parsedEvents = JSON.parse(recording.json);
          setEvents(parsedEvents.data.snapshots);
        }
        await fetchHighlightFeedback(id ?? "")
          .then((data) => {
            setHighlightFeedback(data);
          })
          .catch((error) => {
            console.error("Error fetching highlight feedback", error);
          });
      } catch (error) {
        console.error("Error fetching highlight", error);
        navigate("/not-found");
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [profile, id, navigate, location.pathname]);

  function onExternalLinkClicked(): void {
    if (recording) {
      window.open(recording.ext_resource_url, "_blank");
    }
  }

  async function onFeedback(highlight: Highlight, score: number, summary: string): Promise<void> {
    await insertHighlightFeedback({
      highlight_id: highlight.id!,
      score: score,
      summary: summary,
    })
      .then((data) => {
        console.log("Feedback inserted " + data);
        setHighlightFeedback(data);
      })
      .catch((error) => {
        console.error("Error inserting feedback", error);
      });
  }

  const shareUrl = useMemo(() => {
    if (highlight) {
      return `${import.meta.env.VITE_BASE_URL}/highlights/${highlight.id}`;
    }
    return "";
  }, [highlight]);

  const leftPanelChildren = () => (
    <div className="relative flex h-full flex-col overflow-auto px-6 py-4">
      <p className="mb-5 w-full text-2xl font-bold">{highlight!.title}</p>
      <PPPlayer
        events={events}
        highlight={highlight!}
        shareUrl={shareUrl}
        onExternalLinkClicked={onExternalLinkClicked}
      />
      <div className="absolute inset-y-0 right-0 w-[2px] bg-panel-border" />
    </div>
  );

  const rightPanelChildren = () => (
    <div className="relative h-full px-6 py-4">
      {highlight && (
        <HighlightInfo
          highlight={highlight}
          organization={profile!.organization!}
          recording={recording!}
          lastFeedback={highlightFeedback}
          onFeedback={(score: number, summary?: string) =>
            onFeedback(highlight, score, summary ?? "")
          }
        />
      )}
    </div>
  );

  return (
    <AppLayout>
      {loading && (
        <div className="flex h-[calc(100vh-64px)] items-center justify-center">
          <Spinner label="Loading Highlight" />
        </div>
      )}
      {!loading && (
        <SplitGroupPane
          defaultSizes={["auto", 380]}
          leftPanelChildren={leftPanelChildren()}
          rightPanelChildren={rightPanelChildren()}
        />
      )}
    </AppLayout>
  );
}
