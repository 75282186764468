import { fetchProfileByUserId } from "@/api/services/profiles";
import { signOut } from "@/auth/authHelper";
import { supabaseClient } from "@/helpers/supabase-client";
import { Profile } from "@/types/pptypes";
import { useEffect, useState } from "react";

export const useProfile = () => {
  const [profile, setProfile] = useState<Profile | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);
        const {
          data: { user },
        } = await supabaseClient.auth.getUser();
        if (!user) {
          console.error("Supabase is not returning user data");
          signOut();
          return;
        }
        const profile = await fetchProfileByUserId(user.id);
        //TODO: add logic to register a user with name and lastname.
        profile.display_name = user?.user_metadata.full_name;
        profile.email = user?.user_metadata.email;
        setProfile(profile);
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  return { profile, loading };
};
