import { fetchProfileByUserId } from "@/api/services/profiles";
import { Profile } from "@/types/pptypes";
import { makeAutoObservable } from "mobx";
import { createRequestor } from "mobx-requestor";
import { authStore } from "./authStore";
import { withCachedPromise } from "@/helpers/with-cache-promise";

const getProfile = async (): Promise<Profile> => {
  const session = await authStore.getSession();
  if (!session) {
    throw new Error("No active session found");
  }
  const { user } = session;
  if (!user) {
    throw new Error("No user found in session");
  }
  if (!user.id) {
    throw new Error("No user id found in session");
  }
  const profile = await fetchProfileByUserId(user.id);

  profile.display_name = user?.user_metadata.full_name;
  profile.email = user?.user_metadata.email;

  return profile;
};

export class ProfileStore {
  rq = createRequestor({
    call: getProfile,
  });

  get profile() {
    return this.rq.response;
  }

  get profileLoading() {
    return this.rq.loading;
  }

  get profileError() {
    return this.rq.error;
  }

  get isAdmin() {
    return this.profile?.is_internal;
  }

  constructor() {
    makeAutoObservable(this);
  }

  fetchProfile = withCachedPromise(async () => {
    await this.rq.execCall();
  });

  getProfile = async () => {
    if (!this.profile) {
      await this.fetchProfile();
    }
    return this.profile;
  };
}

export const profileStore = new ProfileStore();
