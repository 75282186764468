import { clearSessionCache } from "@/helpers/cache-helper";
import { supabaseClient } from "@/helpers/supabase-client";

export async function signOut() {
  //remove all session data
  clearSessionCache();

  const { error } = await supabaseClient.auth.signOut();
  if (error) {
    throw Error(error.message);
  }
}
