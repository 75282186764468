import { HighlightWithRecording } from "@/components/highlights/highlights-inbox";
import { Highlight } from "@/types/pptypes";
import { buildUrl, Endpoint } from "./fetch/endpoint";
import { fetchWithSession } from "./fetch/fetch-with-session";

export const fetchHighlightsByRecording = async (recordingId: number) => {
  try {
    const data = await fetchWithSession(
      buildUrl(Endpoint.highlights_by_recording, { recordingId: recordingId }),
    );
    return data as Highlight[];
  } catch (error) {
    console.error(error);
    throw Error("Error fetching highlights by recording");
  }
};

export const fetchHighlightsByOrganization = async (
  organizationId: string,
  page: number = 1,
  pageSize: number = 10,
  sort: string = "c",
) => {
  try {
    const data: { highlights: HighlightWithRecording[]; count: number } = await fetchWithSession(
      buildUrl(
        Endpoint.highlights_by_organization,
        { organizationId: organizationId },
        { page: page, size: pageSize, sort: sort },
      ),
    );
    return {
      ...data,
      currentPage: page,
      pageSize,
      totalPages: Math.ceil((data.count ?? 0) / pageSize),
    };
  } catch (error) {
    console.error(error);
    throw Error("Error fetching highlights by organization");
  }
};
/*
export const fetchHighlightsByOrganization = async (
  organizationId: string,
  page: number = 1,
  pageSize: number = 10
) => {
  const { data, error, count } = await supabase
    .from('highlights')
    .select(`
      *,
      recording:recordings!inner(organization_id, ext_json_file, created_at, ext_resource_url,ext_user_info, ext_timestamp)
    `, { count: 'exact' })
    .eq('recording.organization_id', organizationId)
    .order('created_at', { ascending: false })
    .range((page - 1) * pageSize, page * pageSize - 1);

  if (error) {
    throw new Error(`Error fetching highlights: ${error.message}`);
  }

  return { 
    highlights:data, 
    count,
    currentPage: page,
    pageSize,
    totalPages: Math.ceil((count ?? 0) / pageSize)
  };
}
*/
export async function insertHighlight(highlight: Highlight): Promise<Highlight> {
  try {
    const data = await fetchWithSession(buildUrl(Endpoint.highlights), {
      method: "POST",
      body: highlight,
    });
    return data as Highlight;
  } catch (error) {
    console.error(error);
    throw Error("Error creating Highlight");
  }
}

export const fetchHighlightById = async (highlightId: string) => {
  try {
    const data = await fetchWithSession(
      buildUrl(Endpoint.highlights_by_id, { highlightId: highlightId }),
    );
    return data as HighlightWithRecording;
  } catch (error) {
    console.error(error);
    throw Error("Error fetching highlights by recording");
  }
};
