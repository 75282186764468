import { v4 } from "uuid";
import { FetchOptions, FetchURL, SerializeBodyProps } from "./fetch-types";

export interface ErrorData {
  error: string;
}
export class ResponseError extends Error {
  response?: Response;

  data?: ErrorData;
}
// TODO: use ResponseOrError type to avoid try/catch
export async function fetchJSON<T, K = undefined>(
  url: FetchURL,
  params?: SerializeBodyProps<K>,
): Promise<T> {
  const { serializeBody = true, method, ...rest } = params ?? {};
  const fetchMethod = method ?? "GET";

  const props: FetchOptions = {
    ...rest,
    method: fetchMethod,
    headers: {
      "Content-type": "application/json",
      "x-pp-cid": `web-${v4()}`,
      ...rest?.headers,
    },
    body:
      fetchMethod === "GET"
        ? undefined
        : ((serializeBody ? JSON.stringify(params?.body) : params?.body) as FetchOptions["body"]),
  };

  try {
    const resp = await fetch(url, props);
    if (resp.status < 200 || resp.status >= 300) {
      let data: ErrorData;
      try {
        data = await resp.json();
      } catch (parseError) {
        data = { error: (parseError as Error).message ?? "JSON_ERROR_NOT_RECEIVED" };
      }
      const error = new ResponseError(data.error);
      error.response = resp;
      error.data = data;

      throw error;
    }
    // TODO: use tryParse to always return a valid JSON value at this level
    const text = await resp.text();
    const data = text.trim() === "" ? null : JSON.parse(text);
    return data;
  } catch (err) {
    console.error(">>> err", err);
    throw err;
  }
}
