import "@/App.css";

import AuthenticationPage from "@/pages/authentication";
import { Spinner } from "@nextui-org/react";
import { AuthenticatedContent } from "./pages/Authenticated";
import { authStore } from "./stores/authStore";
import { observer } from "mobx-react-lite";

authStore.fetchSession();

export const App = observer(() => {
  // TODO: handle the error case
  const { isLoggedIn, loading } = authStore;
  if (loading) {
    return <Spinner title="Loading..." />;
  } else if (!loading && !isLoggedIn) {
    return <AuthenticationPage />;
  } else if (!loading && isLoggedIn) {
    return <AuthenticatedContent />;
  }
});
