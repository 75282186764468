import { ExternalLink, PauseIcon, PlayIcon, ShareIcon } from "@/components/icons";
import { Icon } from "@iconify/react/dist/iconify.js";
import {
  Button,
  ButtonGroup,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  SelectItem,
  SharedSelection,
  useDisclosure,
} from "@nextui-org/react";
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const speedOptions = [
  { key: "1", label: "1x" },
  { key: "2", label: "2x" },
  { key: "3", label: "3x" },
  { key: "4", label: "4x" },
  { key: "8", label: "8x" },
];
interface PlayerControlsProps {
  playerState: string;
  speed: number;
  shareUrl?: string;
  onSpeedChanged: (speedMultiplier: number) => void;
  onPlayClicked: () => void;
  onPauseClicked: () => void;
  onExternalLinkClicked?: () => void;
  onMaximizeClicked: () => void;
  onShareClicked: () => void;
}

const PlayerControls: React.FC<PlayerControlsProps> = ({
  playerState,
  speed,
  shareUrl,
  onPlayClicked,
  onPauseClicked,
  onExternalLinkClicked,
  onSpeedChanged,
  onMaximizeClicked,
  onShareClicked,
}) => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [selectedKeys, setSelectedKeys] = useState([`${speed}`]);
  function handleCopy() {
    if (!shareUrl) {
      return;
    }

    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        toast.success("Link Copied");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  }

  function handleSpeedChange(keys: SharedSelection) {
    const selectedKeys = Array.from(keys) as string[];
    setSelectedKeys(selectedKeys);
    onSpeedChanged(parseInt(selectedKeys[0] ?? "1", 10));
  }
  return (
    <div className="flex h-[50px] w-full flex-row justify-between rounded-md bg-black/5">
      <div className="flex  gap-5 px-5" id="left-controls">
        <Button
          className="m-auto bg-transparent"
          isIconOnly
          aria-label="Maximize"
          color="default"
          onPress={onMaximizeClicked}
        >
          <Icon icon="solar:maximize-square-bold" width={30} height={30} />
        </Button>
        <Select
          variant={"flat"}
          aria-label="Speed"
          selectedKeys={selectedKeys}
          className="m-auto w-[70px]"
          fullWidth={false}
          items={speedOptions}
          onSelectionChange={handleSpeedChange}
        >
          {(speed) => {
            return <SelectItem key={speed.key}>{speed.label}</SelectItem>;
          }}
        </Select>
      </div>
      <ButtonGroup className="w-full gap-5 bg-transparent px-5">
        {playerState === "paused" ? (
          <Button
            className="bg-transparent"
            onPress={onPlayClicked}
            isIconOnly
            aria-label="Toggle"
            color="default"
          >
            <PlayIcon />
          </Button>
        ) : (
          <Button
            className="bg-transparent"
            onPress={onPauseClicked}
            isIconOnly
            aria-label="Toggle"
            color="default"
          >
            <PauseIcon />
          </Button>
        )}
      </ButtonGroup>
      <ButtonGroup className="w-[200px] gap-5 bg-transparent px-5">
        <Button
          className="rounded bg-transparent p-1"
          onPress={onExternalLinkClicked}
          isIconOnly
          aria-label="External Link"
          color="default"
        >
          <ExternalLink />
        </Button>
        <Button
          className="rounded bg-transparent p-1"
          onPress={() => {
            onOpen();
            onShareClicked();
          }}
          isIconOnly
          aria-label="Share Link"
          color="default"
        >
          <ShareIcon />
        </Button>
      </ButtonGroup>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Share Highlight</ModalHeader>
              <ModalBody>
                <p>
                  <b>This url is private and only users in your organization will have access.</b>
                </p>
                <Input readOnly value={shareUrl} fullWidth />
              </ModalBody>
              <ModalFooter>
                <Button
                  onPress={() => {
                    handleCopy();
                    onClose();
                  }}
                >
                  Copy Link
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        theme="light"
      />
    </div>
  );
};

export default PlayerControls;
