import { useProfile } from "@/hooks/use-profile";
import { useEffect } from "react";
import { signOut } from "./authHelper";

export function useAdminAuth() {
  const { profile, loading } = useProfile();

  useEffect(() => {
    if (!loading && (!profile || !profile.is_internal)) {
      signOut();
    }
  }, [profile, loading]);

  return { isAdmin: profile?.is_internal, loading };
}
