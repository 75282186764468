import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";

import { useViewportBreakpoints } from "@/helpers/screen";
import clsx from "clsx";
import { supabaseClient } from "@/helpers/supabase-client";

function AuthenticationPage() {
  const { cnByMatchesH, hMatches } = useViewportBreakpoints();

  return (
    <div
      className={clsx(
        "max-w-[500px]",
        cnByMatchesH({
          lg: "h-dvh flex flex-col items-center justify-center w-full",
        }),
        {
          "px-8 py-6 mx-auto": hMatches.xss,
        },
      )}
    >
      <div className={clsx({ "w-[500px]": hMatches.lg })}>
        <h2 className="text-center text-2xl font-bold">Log in</h2>
        <Auth
          supabaseClient={supabaseClient}
          appearance={{ theme: ThemeSupa }}
          providers={["google"]}
        />
      </div>
    </div>
  );
}

export default AuthenticationPage;
