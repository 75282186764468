import { fetchFileFromSupabase } from "@/api/services/recordings";
import HighlightsInbox from "@/components/highlights/highlights-inbox";
import PPPlayer from "@/components/player/pp-player";
import SUHomeControls from "@/components/superuser/su-home-controls";
import TruncatedText from "@/components/truncated-text";

import { AppLayout } from "@/layouts/default";
import { Highlight, Organization, Recording, RecordingEvent } from "@/types/pptypes";
import { Button, Spinner } from "@nextui-org/react";
import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { SplitGroupPane } from "@/components/split-group-pane";
import { profileStore } from "@/stores/profileStore";
import { observer } from "mobx-react-lite";
import { useViewportBreakpoints } from "@/helpers/screen";
import clsx from "clsx";

const HighlightsPage = observer(() => {
  const { hMatches } = useViewportBreakpoints();
  const [selectedHighlight, setSelectedHighlight] = useState<Highlight | null>(null);
  const [selectedRecording, setSelectedRecording] = useState<Recording | null>(null);
  const { profile } = profileStore;
  const [currentOrganization, setCurrentOrganization] = useState<Organization | null>(null);
  const navigate = useNavigate();
  const [events, setEvents] = useState<RecordingEvent[]>([]);

  async function onHighLightClick(highlight: Highlight, recording: Recording) {
    setSelectedHighlight(highlight);
    setSelectedRecording(recording);

    if (!recording.json) {
      const jsonContent = await fetchFileFromSupabase(recording);
      const parsedEvents = JSON.parse(jsonContent);
      setEvents(parsedEvents.data.snapshots);
    } else {
      const parsedEvents = JSON.parse(recording.json);
      setEvents(parsedEvents.data.snapshots);
    }
  }

  // Update currentOrganization when profile changes please
  useEffect(() => {
    if (profile) {
      if (profile.organization) {
        setCurrentOrganization(profile.organization);
      } else {
        navigate("/onboarding"); // Redirect to onboarding page
      }
    }
  }, [profile, navigate]);

  function onOrganizationSelected(organization: Organization): void {
    setCurrentOrganization(organization);
  }

  function onExternalLinkClicked(): void {
    if (selectedRecording && selectedHighlight) {
      const url = `${selectedRecording.ext_resource_url}?t=${selectedHighlight.start_time_in_s}`;
      window.open(url, "_blank");
    }
  }

  const shareUrl = useMemo(() => {
    if (selectedRecording && selectedHighlight) {
      return `${import.meta.env.VITE_BASE_URL}/highlights/${selectedHighlight.id}`;
    }
    return "";
  }, [selectedRecording, selectedHighlight]);

  const rightPanelChildren = () => (
    <div className="relative h-full overflow-auto">
      {!events ||
        (events.length === 0 && (
          <div className="flex size-full flex-col items-center justify-center">
            <h2>Select a highlight to view it</h2>
          </div>
        ))}
      {events && events.length > 0 && (
        <div className={clsx("px-6 py-2", { "[&&]:px-8 [&&]:pb-4 [&&]:pt-[18px]": hMatches.md })}>
          <div className="mb-[20px]">
            {!hMatches.md && (
              <div className="mb-5 w-[120px]">
                <Button
                  color="primary"
                  variant="flat"
                  onClick={() => setEvents([])}
                  className="w-full"
                >
                  Back to list
                </Button>
              </div>
            )}

            <h1
              className={clsx("mb-5 mt-1 cursor-pointer text-[20px] font-bold", {
                "[&&]:text-[28px]": hMatches.md,
              })}
            >
              <Link to={`/highlights/${selectedHighlight!.id}`} className="hover:underline">
                {selectedHighlight!.title}
              </Link>
            </h1>

            <TruncatedText text={selectedHighlight!.summary} maxLength={400} shouldExpand={true} />
          </div>
          <div className="w-full">
            <PPPlayer
              events={events}
              highlight={selectedHighlight!}
              shareUrl={shareUrl}
              onExternalLinkClicked={onExternalLinkClicked}
            />
          </div>
        </div>
      )}
    </div>
  );

  const leftPanelChildren = () => (
    <div className="relative flex h-full min-w-[320px] flex-col">
      <SUHomeControls
        currentOrganization={currentOrganization}
        handleOrganizationSelected={onOrganizationSelected}
      />

      {currentOrganization && (
        <HighlightsInbox
          selectedHighlight={selectedHighlight}
          organization={currentOrganization}
          className="flex-1 "
          onHighlightClick={onHighLightClick}
        />
      )}

      <div className="absolute inset-y-0 right-0 w-[2px] bg-panel-border" />
    </div>
  );

  return (
    <AppLayout>
      {!currentOrganization ? (
        <div className="relative flex h-[calc(100vh-74px)] items-center justify-center">
          <Spinner label="Loading Data" />
        </div>
      ) : (
        <>
          {hMatches.md && (
            <SplitGroupPane
              leftPaneProps={{ minSize: 320 }}
              defaultSizes={[320, "auto"]}
              leftPanelChildren={leftPanelChildren()}
              rightPanelChildren={rightPanelChildren()}
            />
          )}
          {!hMatches.md && (
            <div className="relative h-[calc(100vh-74px)] pt-3">
              {(!events || events.length === 0) && leftPanelChildren()}
              {events && events.length > 0 && rightPanelChildren()}
              <div className="absolute inset-x-0 top-0 h-[2px] bg-panel-border" />
            </div>
          )}
        </>
      )}
    </AppLayout>
  );
});

export default HighlightsPage;
