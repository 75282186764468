import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DefaultLayout from "@/layouts/default";
import { Button, Input, Select, SelectItem, Spinner } from "@nextui-org/react";
import { Organization, Subscription } from "@/types/pptypes";
import { getAllSubscriptions } from "@/api/services/subscription";
import { createOrganization } from "@/api/services/organizations";
import { toast, ToastContainer } from "react-toastify";
import { useAdminAuth } from "@/auth/use-admin-auth";

export default function RegisterOrg() {
  const { isAdmin, loading: authLoading } = useAdminAuth();
  const [formData, setFormData] = useState<Partial<Organization>>({
    subscription_id: 3, // Set default subscription_id
  });
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (authLoading || !isAdmin) {
      return;
    }
    const fetchSubscriptions = async () => {
      try {
        const allSubscriptions = await getAllSubscriptions();
        setSubscriptions(allSubscriptions);
      } catch (error) {
        console.error("Error fetching subscriptions:", error);
      }
    };

    fetchSubscriptions();
  }, [authLoading, isAdmin]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFormData({ ...formData, subscription_id: Number(e.target.value) });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await createOrganization(formData).then(() => navigate("/admin"));
    } catch (error) {
      toast.error("Error creating organization");
      console.error("Error creating organization:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <DefaultLayout>
      <main className="flex w-full justify-center">
        <div className="flex w-[400px] flex-col gap-5">
          <h1 className="text-large font-medium">Register Organization</h1>
          <form onSubmit={handleSubmit} className="flex flex-col gap-5">
            <Input required label="Name" name="name" onChange={handleInputChange} />
            <Input
              required
              label="Domain Access"
              name="domain_access"
              onChange={handleInputChange}
            />
            <Select
              label="Subscription Type"
              name="subscription_id"
              onChange={handleSelectChange}
              selectedKeys={[`${formData.subscription_id}`]}
            >
              {subscriptions.map((subscription) => (
                <SelectItem key={subscription.id} value={subscription.id}>
                  {subscription.name}
                </SelectItem>
              ))}
            </Select>
            <Input label="Logo URL" name="logo_url" onChange={handleInputChange} />
            <Button color="primary" type="submit" disabled={isLoading}>
              {isLoading ? <Spinner size="sm" /> : "Register"}
            </Button>
          </form>
        </div>
        <ToastContainer />
      </main>
    </DefaultLayout>
  );
}
