import { fetchMatchingOrganizations } from "@/api/services/organizations";
import { completeOnboarding } from "@/api/services/profiles";
import { useProfile } from "@/hooks/use-profile";
import DefaultLayout from "@/layouts/default";
import { Organization } from "@/types/pptypes";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import OnboardingCard from "@/components/onboarding-card";
import { Spinner } from "@nextui-org/react";
import posthog from "posthog-js";
import { useLocation } from "react-router-dom";
import { extractDomainFromEmail } from "@/helpers/email";

export default function OnboardingPage() {
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const { profile } = useProfile();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setIsLoading(true);
    if (profile && profile.email) {
      const fetchOrganizations = async () => {
        const data = await fetchMatchingOrganizations(extractDomainFromEmail(profile.email!));
        setOrganizations(data);
        setIsLoading(false);
      };

      fetchOrganizations();
    }
  }, [profile]);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (profile) {
      setIsLoading(true);
      const orgId = organizations.length > 0 ? organizations[0].id : undefined;
      completeOnboarding(profile?.id, firstName, lastName, orgId).then(() => {
        posthog.capture("onboarding_submitted", { email: profile.email, org_id: orgId });
        setIsLoading(false);
        const redirectTo = location.state?.redirect || "/";
        navigate(redirectTo); // Use the redirect URL from state or default to '/'
      });
    } else {
      throw Error("No Profile");
    }
  };

  return (
    <DefaultLayout>
      <main className="mt-10 flex w-full justify-center">
        {profile ? (
          <OnboardingCard
            firstName={firstName}
            lastName={lastName}
            organizations={organizations}
            isLoading={isLoading}
            handleSubmit={handleSubmit}
            setFirstName={setFirstName}
            setLastName={setLastName}
          />
        ) : (
          <Spinner label="Loading Data" />
        )}
      </main>
    </DefaultLayout>
  );
}
